export const messages = {
    en: {
        //tabs
        chat: "Chat",
        feedback: "Feedback",
        resource: "Resources",
        setting: "Account",
        recharge: "Recharge",
        rechargeDetail: "Recharge detail",
        transfer: 'Transfer deer antler',
        profile: "Profile",
        avatar: 'Avatar',
        alias: 'Alias',
        profession: 'Profession',
        age: 'Age',

        //registration, setting
        loginTitle: 'Log in with email and password',
        registerTitle: 'Sign up with email and password',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        login: 'Log in',
        signup: 'Sign up',
        noAccount: "Don't have an account?",
        logout: 'Logout',
        save: 'Save',
        emailConfirmMsg: 'Welcome! Check your email and click the confirmation link',
        pwdNoMatch: 'Passwords do not match',

        loadingText: 'Loading...',

        // added by Jun Wang 2024-03-10
        chatpage_title: 'Chat',
        chat_see_you_again: 'welcome back!',
        chat_if_suicide_thought: 'If you have had any thoughts of harming yourself or suicidal thoughts in the past month, please',
        chat_click_here: 'click here',
        // TO_ADD_BY_BOT_EN

        feedback_title: 'Feedback',
        feedback_welcome: 'Please feel free to give us your',
        feedback_line: 'feedback, suggestions, and comments.',

        resource_title: 'Resources',
        resource_teacher_ellen: 'Teacher Ellen leads: Body meditation practice',
        resource_feeling_awareness_example: 'Examples of sensing and observing: What I want to say',
        resource_cognitive_behavior_example: 'Examples of cognitive behavior',
        resource_suicide_help_hotline: 'Suicide intervention, psychological assistance hotline',
        resource_feeling_vocabulary: 'Emotional feeling vocabulary',
        resource_need_vocabulary: 'Emotional need vocabulary',

        settingPage: {
            antlers: 'Antlers',
            antlersNum: 'Antlers num',
            rewardRules: 'Reward rules',
            give: 'Give',
            reCharge: 'Recharge',
            recmTransfer: 'Transfer',
            myCode: 'My code',
            myRecmCode: 'My referral code',
            copy: 'Copy',
            view: 'View',
            more: 'More',
            recmCodeTips: 'Inform others that they can accept deer antlers as gifts',
            share: 'Share',
        }
    },
    zh: {
        //tabs
        chat: "谈话",
        feedback: "反馈",
        resource: "资源",
        setting: "我的",
        recharge: "充值",
        rechargeDetail: "鹿角变动明细",
        transfer: '转赠鹿角',
        profile: "个人信息",
        avatar: '头像',
        alias: '昵称',
        profession: '工作',
        age: '年龄',

        //registration, setting
        loginTitle: '请用电邮登录',
        registerTitle: '请用电邮注册新账户',
        email: '电邮',
        password: '密码',
        confirmPassword: '再次输入密码',
        login: '登录',
        signup: '注册',
        noAccount: "还没有账户?",
        logout: '登出',
        save: '保存',
        emailConfirmMsg: '欢迎! 请查收电邮，并点击邮件中的确认链接',
        pwdNoMatch: '输入的两个密码不匹配',

        loadingText: '加载中...',

        // added by Jun Wang 2024-03-10
        chatpage_title: '谈话',
        chat_see_you_again: '你好，我们又见面了。',
        chat_if_suicide_thought: '如果你最近一个月内有过轻生的念头，请',
        chat_click_here: '点击这里',
        // TO_ADD_BY_BOT_ZH

        feedback_title: '反馈',
        feedback_welcome: '欢迎随时反馈',
        feedback_line: '使用过程中的问题、意见、建议',

        resource_title: '资源',
        resource_teacher_ellen: 'Ellen 老师带领：身体冥想练习',
        resource_feeling_awareness_example: '感受觉察示例：我想说的话',
        resource_cognitive_behavior_example: '认知行为示例',
        resource_suicide_help_hotline: '自杀干预、心理援助热线',
        resource_feeling_vocabulary: '感受词汇表',
        resource_need_vocabulary: '需要词汇表',

        settingPage: {
            antlers: '鹿角',
            antlersNum: '鹿角数量',
            rewardRules: '奖励规则',
            give: '赠送',
            reCharge: '充值',
            recmTransfer: '推荐转赠',
            myCode: '我的编号',
            myRecmCode: '我的推荐码',
            copy: '复制',
            view: '查看',
            more: '查看更多',
            recmCodeTips: '告知他人，可接受对方转赠的鹿角',
            share: '分享',
        }
    },

};

