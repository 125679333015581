<template>
  <base-layout :page-title=chatpage_title>
    <template v-slot:actions-end v-if="session?.user">
      <ion-button router-link="/review" v-if="debug">
        <ion-icon slot="icon-only" :icon="chevronBackCircle"></ion-icon>
      </ion-button>
    </template>

    <div v-if="isLoading">
      <LoadingScreen></LoadingScreen>
    </div>
    <div v-else>
      <div v-if="!session?.user?.id" class="ion-padding ion-text-center">
        <p>
          <ion-label color="primary">
        <h1>Please <a href=/>sign in</a> first.</h1></ion-label></p>
      </div>
      <div v-else>
        <div id='div_greeting' v-if="show_greeting">
          <div v-if="!new_user">
            <ChatMessage class='hidden'>{{ user_alias }}，{{t('chat_see_you_again')}}</ChatMessage>
          </div>
          <div v-else id='div_first_time'>
            <div id="div_ask_alias">
              <ChatMessage>Hello !</ChatMessage>
              <ChatMessage>My name is: Giraffe Ear. </ChatMessage>
              <ChatMessage>I am: A psychological counseling chatbot. </ChatMessage>
              <ChatMessage>My strengths are: A big heart, vision and foresight, gentle patience, and skill in listening and inspiring. </ChatMessage>
              <ChatMessage>I use humanistic psychology and cognitive-behavioral therapy, etc., to help you explore yourself and resolve psychological troubles. </ChatMessage>
              <ChatMessage>This is not simply giving advice, but a more in-depth and effective approach. </ChatMessage>
              <ChatMessage>It‘s our first meeting, how should I address you? </ChatMessage>
              <ChatInputArea v-model="user_alias" placeholder=" Please enter your nickname here. ..."
                             @click_submit="submit_my_username()">
              </ChatInputArea>
            </div>
          </div>
          <div v-if="show_charge_info" id='div_charge_info'>
            <ChatMessage v-if='!new_user' class='hidden'>{{t('chat_if_suicide_thought')}}
              <router-link :to="{name:'HotLine'}">{{t('chat_click_here')}}</router-link>
              。
            </ChatMessage>
            <ChatMessage class='hidden'>Warm reminder: <br/>
              *  Good experiences come from genuine needs; the antler tokens for the first two experiences are provided by the Giraffe, so please cherish them. <br/>
              *  Your conversation with the Giraffe Ear will be kept completely confidential. The more you open up, the more effective the session you will have. <br/>
            </ChatMessage>
           <!--   <ChatMessage class='hidden'>
              <b> If you don‘t have any strong troubles right now, and are just curious to understand how a robot can provide psychological counseling, please first see: 
                <a href="https://mp.weixin.qq.com/s/3A3bBDIARsKwKOVU8Y-EqQ" target="_blank"> This experience example </a>
              </b>
            </ChatMessage>-->
            <ChatMessage v-if='session.user.need_money && session.user.need_money>0' class='hidden'>You don‘t have enough antler tokens currently, please 
              <router-link :to="{path:'/recharge'}">
<b>Recharge  {{ session.user.need_money || '' }}  Yuan</b></router-link>The above, before we can continue. 
            </ChatMessage>
            <div class="center hidden">
              <ion-button shape="round" @click="chat_greeting" class="navBtn">OK</ion-button>
            </div>
          </div>
          <div v-if="show_chat_start" id="div_chat_start">
            <!-- <ChatMessage class='hidden'>Thank you for choosing me, I‘m right here, willing to listen to your troubles at any time. </ChatMessage>  -->
            <ChatMessage class='hidden'>{{t('chat_if_suicide_thought')}}
              <router-link :to="{name:'HotLine'}">{{t('chat_click_here')}}
              </router-link>
              。
            </ChatMessage>
            <ChatMessage>I use humanistic psychology and cognitive-behavioral therapy, etc., to help you understand yourself better and resolve psychological troubles. </ChatMessage>
            <ChatMessage class='hidden'>It is not simply giving advice, but a more in-depth and effective approach. </ChatMessage>
            <ChatMessage class='hidden'>It takes 20-60 minutes per session, depending on your pace. </ChatMessage>            
            <ChatMessage class='hidden'>Taking the time to slow down and think is essential in emotional management. </ChatMessage>
            <!-- <ChatMessage class='hidden'>Here, <b>You are free, your feelings are most important. </b></ChatMessage> -->
            <ChatMessage class='hidden'>For questions or suggestions, switch to the "Feedback" page below and switch back afterwards. </ChatMessage>
            <ChatMessage class='hidden'>Now, let me hear you. </ChatMessage>
            <div class="center hidden">
              <ion-button shape="round" @click="start_chat_ai" class="navBtn"> Start a session </ion-button>
            </div>
            <!-- <ion-button color="secondary" fill="clear" @click="start_chat_ai" class="center hidden btnStart" >
              <ion-icon :icon="chevronForwardCircle" /> Start 
            </ion-button>
            <div v-if="debug">
              <ion-button color="secondary" fill="clear" @click="start_chat" class="center hidden" >
                <ion-icon :icon="chevronForwardCircle" /> Old version 
              </ion-button>
            </div> -->
          </div>
        </div>

        <Transition>
          <div v-if="show_ai_block" id="div_ai">
            <div id="ai_warmup">
              <ChatMessage class='hidden'>Thank you for allowing me to understand you better. </ChatMessage>
              <ChatMessage class='hidden'>Let‘s try to sort out your thoughts together. </ChatMessage>
            </div>
            <div id="div_ai_choice">
              <div class='choiceGroup' v-if="show_ai_result">
                <ChatMessage v-if="leaf_choices.length >1"> Which of the following topics would you most like to discuss? </ChatMessage>
                <ChatMessage v-else>What you want to discuss, is it: </ChatMessage>
                <div v-for="(choice, i) in leaf_choices" :key="i" @click="click_ai_choice(choice)">
                  <ChatChoice>{{ choice }}</ChatChoice>
                </div>
                <div @click="click_ai_choice(-1)">
                  <ChatChoice v-if="leaf_choices.length > 1"> None of the above topics </ChatChoice>
                  <ChatChoice v-else> Not </ChatChoice>
                </div>
              </div>
              <div v-if="ai_weak">
                <ChatMessage v-if="!leaf_choices.length">{{ user_alias }}, in this whole situation, what troubles you the most? </ChatMessage>                <ChatInputArea v-model="ai_weak_topic" :placeholder="ai_weak_placeholder"
                               @click_submit="submit_ai_weak()">
                </ChatInputArea>
              </div>
            </div>
            <!-- <div id="ai_choice_msg" class="hidden">
              <ChatMessage>{{user_alias}},  Thank you for telling me that what you want to discuss is: {{ ai_weak_topic }}</ChatMessage>            </div> -->
          </div>
        </Transition>

        <Transition>
          <div v-if="show_tree_node_block" id="div_tree" class='transition'>
            <ChatMessage>{{ user_alias }}, {{ node_selection_question }}</ChatMessage>
            <div class='choiceGroup' id="tree_choice">
              <div v-for="choice in choices" :key="choice.id" @click="click_tree_node(choice.id)">
                <ChatChoice>{{ choice.choice }}</ChatChoice>
              </div>
              <ion-button v-if="parent_id >= 0" color="secondary" fill="clear" class='center'
                          @click="click_tree_node(parent_id)">
                <ion-icon :icon="chevronBackCircle"/>
                 Return 
              </ion-button>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_start_score_block" id="div_start_score">
            <ChatMessage>If you were to rate your troubles ( 0-10）</ChatMessage>
            <ChatMessage>On a scale of 0 to 10, where 0 is the most calm and 10 is the most intense, how would you rate it? </ChatMessage>
            <div class='choiceGroup'>
              <div v-for="i in 11" :key="i" @click="click_score(i, 0)">
                <ChatChoice>{{ i-1 }} </ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_details_input_block" id="div_input">
            <div v-if="ai_more_input">
              <div id="messagesWrapper">
                <ChatMessage v-for="(message, i) in chat_messages" :key="i" class="chat-message"
                             :fromUser="i % 2 == 1"> {{ message }}
                </ChatMessage>
              </div>
              <div id="div_details" class="bottom-input">
                <ion-button fill="clear" style="margin-bottom: 10px;"
                            @click="ai_more_details(1)">
                  <ion-icon :icon="chevronForwardCircle"/>
                  {{ ai_detail_no }}
                </ion-button>
                <ChatInputArea v-model="user_input_details" :placeholder="input_prompt" :disabled='disableInput'
                               @click_submit="finish_the_details()"></ChatInputArea>
              </div>
            </div>
            <div v-else>
              <ChatMessage><span id="span_question"><span></span>{{ user_input_question }}</span>
              </ChatMessage>
              <ChatMessage v-if="ai_require_input" id="div_ai_require">I really want to listen to and understand you more effectively, please say a bit more. 
              </ChatMessage>
              <ChatInputArea v-model="user_input_details" :placeholder="input_prompt"
                             @click_submit="finish_the_details()"></ChatInputArea>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feelings_block" id="div_feeling">
            <ChatMessage><span v-if="first_feeling">{{ user_alias }}，{{ first_feeling_prompt }}</span>
              <span v-else> Hmm </span> Did you feel <b>{{ feeling_curr }}</b>?
            </ChatMessage>
            <div class='choiceGroup'>
              <div @click="click_feeling('yes')">
                <ChatChoice> Yes ,  I feel {{ feeling_curr }}</ChatChoice>
              </div>
              <div @click="click_feeling('no')">
                <ChatChoice> Not too bad ,  Didn‘t feel {{ feeling_curr }}</ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feeling_detail_block" id="div_feeling_detail">
            <ChatMessage>{{ feeling_detail_question }}
<router-link :to="{name:'FeelingDetail'}"><b>Example</b></router-link>            </ChatMessage>
            <ChatInputArea v-model="feeling_detail" @click_submit="submit_feeling_detail()">
            </ChatInputArea>
            <ion-button fill="clear" class="ion-float-right endChoice"
                        @click="submit_feeling_detail()">
              <ion-icon :icon="chevronForwardCircle"/>
               Nothing to say. 
            </ion-button>
            <ion-button fill="clear" class="ion-float-left endChoice" router-link="/review">
              <ion-icon :icon="chevronBackCircle"/>
               Review 
            </ion-button>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feeling_choices_block" id="div_feeling_choices">
            <ChatMessage>{{ user_alias }}，{{ first_feeling_prompt }}</ChatMessage>
            <ChatMessage>I‘ve listed some feelings. <br/>Please slow down and check the ones you have. </ChatMessage>
            <ion-item v-for="(feeling, i) in feeling_choices" :key="i" lines="none" class="transparent checkItem">
              <ion-checkbox slot="start" v-model="checked_feelings[feeling]"></ion-checkbox>
              <ion-label>{{ feeling }}</ion-label>
            </ion-item>
            <ChatSubmit @click_submit="submit_feeling_choices()" class='endChoice'/>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_body_block" id="div_body">
            <ChatMessage>Do the feelings trigger any physical discomfort for you? </ChatMessage>
            <div class='choiceGroup'>
              <div @click="click_body('yes')">
                <ChatChoice> Yes </ChatChoice>
              </div>
              <div @click="click_body('no')">
                <ChatChoice> No </ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_body_detail_block" id="div_body_detail">
            <ChatMessage class='hidden'>What kind of physical reactions do you have?</ChatMessage>
            <ChatMessage class='hidden'>For example: tight chest, a lump in the throat, tense shoulder, headache, etc.</ChatMessage>
            <div class='hidden'>
              <ChatInputArea v-model="body_detail" @click_submit="submit_body_detail()"></ChatInputArea>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feeling_other_block" id="div_feeling_other">
            <ChatMessage class='hidden'>Thank you for your openness and trust. </ChatMessage>
            <div v-if="feeling_chosen">
              <ChatMessage class='hidden'>Based on the content we‘ve sorted out above, let‘s summarize your feelings together. </ChatMessage>
              <ChatMessage class='hidden'>You feel <b>{{ feeling_chosen }}</b></ChatMessage>
            </div>
            <ChatMessage class='hidden' v-if="has_body_sensation">You have <b>Physical discomfort </b>
              <span v-if="body_detail">：<b>{{ body_detail }}</b></span></ChatMessage>
            <ChatMessage class='hidden'>
              <span v-if="g_feelings_yes.length"> Do you still have any other feelings? </span>
              <span v-else> Do you have any other feelings? </span>
               For instance: <i>{{ feeling_hint }}</i> ......
            </ChatMessage>
            <ChatMessage class='hidden'>You can view 
<router-link :to="{name:'Feeling'}">Feelings vocabulary list</router-link>            </ChatMessage>
            <div class='choiceGroup hidden' id='feeling_other_choice'>
              <div @click="click_feeling_other('no')">
                <ChatChoice> No more </ChatChoice>
              </div>
              <div @click="click_feeling_other('yes')">
                <ChatChoice> Yes, I have other feelings. </ChatChoice>
              </div>
            </div>
            <div v-if='show_feeling_other_input_block'>
              <ChatInputArea v-model="feeling_other" placeholder=" Please enter the main one. 1-3 Other feelings. You also feel: "
                             @click_submit="submit_feeling_other()"></ChatInputArea>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feeling_order_block" id="div_feeling_order">
            <ChatMessage>{{ feeling_order_prompt }}</ChatMessage>
            <div class='choiceGroup'>
              <div v-for="(choice, i) in feeling_choices" :key="i" @click="click_feeling_order(i)">
                <ChatChoice>{{ choice.split('：')[0] }}</ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_body_ex_block" id="div_body_ex">
            <ChatMessage class='hidden'>{{ user_alias }}, you have some physical discomfort. </ChatMessage>            
            <ChatMessage class='hidden'>Now, I invite you to follow along with  Ellen  The teacher will guide a meditation exercise on body sensations. </ChatMessage>            <ChatMessage class='hidden'>When you're ready, you can press "Play" to begin the meditation. </ChatMessage>
            <div class='hidden'>
              <img id='body_ex_bg' src="/assets/icon/meditation.gif"/>
              <!-- <video id='body_ex_bg' src="/assets/icon/meditation.mp4" autoplay loop webkit-playsinline playsinline/> -->
              <div class='center'>
                <ion-button id="ex_audio_btn" shape='round' class='navBtn' @click="toggleAudio()"> Play </ion-button>
              </div>
            </div>
            <div class="hidden bottom-input">
              <ion-button id="body_ex_next" fill="clear" class="ion-float-right"
                          @click="show_imagine">
                <ion-icon :icon="chevronForwardCircle"/>
                 Skip 
              </ion-button>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_imagine_block" id='div_imagine'>
            <div id="imagine_guide">
              <ChatMessage class='hidden'>Based on your feelings, I‘d like to invite you to do another excercise. </ChatMessage>
              <ChatMessage class='hidden'>Gently close your eyes and turn your attention inward to yourself. </ChatMessage>
              <ChatMessage class='hidden'>You will gradually see an image appear. Stay with it for a while, then describe the image. </ChatMessage>
              <!-- <ChatMessage class='hidden'>For example, like a horse struggling to pull a heavy cart. </ChatMessage>
              <ChatMessage class='hidden'>For example, it‘s like standing on a beach and looking out at the endless ocean, not knowing which direction to go. </ChatMessage>
              <ChatMessage class='hidden'>{{ user_alias }} Please gently close your eyes and take two minutes to visualize your image. </ChatMessage>-->
            </div>
            <div class='choiceGroup hidden' id='imagine_choice'>
              <div @click="click_imagine('yes')">
                <ChatChoice> There is an image </ChatChoice>
              </div>
              <div @click="click_imagine('no')">
                <ChatChoice> No image </ChatChoice>
              </div>
            </div>
            <div v-if="show_imagine_detail_block" id="div_imagine_detail">
              <ChatMessage>What is this image like? </ChatMessage>
              <ChatInputArea v-model="imagine_detail" @click_submit="submit_imagine_detail()">
              </ChatInputArea>
            </div>
            <div v-if="ask_new_feeling">
              <ChatMessage>{{ user_alias }}, when you were describing that image, </ChatMessage>              <ChatMessage v-if="prime_emotions">Besides what was mentioned before, <b>{{ prime_emotions }}</b>...</ChatMessage>
              <ChatMessage>Will there be some new emotions coming up? 
<router-link :to="{name:'Feeling'}">Feelings vocabulary list</router-link>              </ChatMessage>
              <div class='choiceGroup' id="feeling_new_choice">
                <div @click="click_feeling_new('yes')">
                  <ChatChoice> I have a new emotion. </ChatChoice>
                </div>
                <div @click="click_feeling_new('no')">
                  <ChatChoice> None </ChatChoice>
                </div>
              </div>
              <div v-if="show_new_feeling_block">
                <ChatInputArea v-model="feeling_new" @click_submit="submit_new_feeling()"
                               placeholder=" Please enter the new emotion. "></ChatInputArea>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_needs_block" id='div_need'>
            <div v-if="need_detail_confirm" id="div_need_confirm">
              <ChatMessage>Hmm {{ need_repeat_start }}</ChatMessage>
              <ChatMessage>{{ need_repeat }}</ChatMessage>
              <div class='pause'></div>
            </div>
            <div id="div_need_prompt">
              <div v-if="first_need">
                <ChatMessage>{{ user_alias }}, you bravely faced your own feelings, which is an important process of self-understanding. </ChatMessage>                <ChatMessage v-if="g_user_chat_history.feeling_new">
                   Thank you also for letting me know that you have a new feeling: {{ g_user_chat_history.feeling_new }}
                </ChatMessage>
                <ChatMessage>Next, we're going a bit deeper. </ChatMessage>
                <ChatMessage v-if="prime_feeling">You feel {{ prime_feeling }}. Is it possible that it‘s because: </ChatMessage>              </div>
              <ChatMessage id="need_question"><span v-if="!first_need"></span>
                {{ do_you_need_question }}
              </ChatMessage>
              <div class='choiceGroup' id='need_choice'>
                <div @click="click_need('yes')">
                  <ChatChoice> Yes </ChatChoice>
                </div>
                <div @click="click_need('no')">
                  <ChatChoice> Not relevant </ChatChoice>
                </div>
              </div>
            </div>
            <div v-if="show_need_detail_block" id="div_need_detail">
              <ChatMessage>Describe what this means to you. <br/>(Then you can add how much you desire it, how long you‘ve desired it, etc...）</ChatMessage>
              <ChatInputArea v-model="need_detail" @click_submit="submit_need_detail()"></ChatInputArea>
              <ion-button fill="clear" class="ion-float-right endChoice"
                          @click="submit_need_detail()">
                <ion-icon :icon="chevronForwardCircle"/>
                 Nothing more to say. 
              </ion-button>
              <ion-button fill="clear" class="ion-float-left endChoice" router-link="/review">
                <ion-icon :icon="chevronBackCircle"/>
                 Review 
              </ion-button>
            </div>
            <!-- <div v-if="need_detail_confirm" id="div_need_confirm">
              <ChatMessage class='hidden'>Hmm {{user_alias}}，{{need_repeat_start}}</ChatMessage>
              <ChatMessage class='hidden'>{{need_repeat}}</ChatMessage>
              <div class="hidden">
                <ion-button fill="clear" class="ion-float-right" @click="show_next_conversation_of_need(false)">
                <ion-icon :icon="chevronForwardCircle" /> Continue  </ion-button>
              </div>
            </div> -->
          </div>
        </Transition>

        <Transition>
          <div v-show="show_recap_block" id="div_recap">
            <div v-for="(sentence, index) in recaps" :key="index" class='hidden' :id="`recap${index}`">
              <div v-if="sentence">
                <ChatMessage> {{ sentence }}</ChatMessage>
              </div>
              <div v-else class='pause'></div>
            </div>
            <div id='recap_choice' class='hidden'>
              <ChatMessage>
                <span v-if="g_user_chat_history.feelings_yes || g_user_chat_history.feeling_new"> Would you like me to be quiet with you, so you can spend some time with your feelings? </span>
                <span v-else>  Would you like me to stay with you and be quiet for a while? </span>
              </ChatMessage>
              <div class='choiceGroup'>
                <div @click="click_cooldown('Y')">
                  <ChatChoice> Okay </ChatChoice>
                </div>
                <div @click="click_cooldown('N')">
                  <ChatChoice> No. Thank you. </ChatChoice>
                </div>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-show="show_cool_down_block" id='div_cooldown'>
            <div v-for="(sentence, index) in cool_downs" :key="index" class='hidden' :id="`cool_down${index}`">
              <div v-if="sentence">
                <ChatMessage> {{ sentence }}</ChatMessage>
              </div>
              <div v-else class='pause'></div>
            </div>
            <!-- <ion-icon color="primary" size="large" :icon="volumeMuteOutline"/> -->
            <div id="cool_down_nature" class="hidden">
              <ion-button fill="clear" class="ion-float-right"
                          @click="show_positive">
                <ion-icon :icon="chevronForwardCircle"/>
                 Next step 
              </ion-button>
              <ion-item lines="none" class="transparent">
                <ion-label> Sound </ion-label>
                <ion-toggle slot="start" mode="ios" id="natureToggle"
                            @ionChange="toggleNatureAudio($event.target.checked)"
                            checked>
                </ion-toggle>
                <ion-select id="natureSelect" interface="popover" v-model="nature" mode="ios" text-center
                            @ionChange="playNatureSound()">
                  <ion-select-option value='雨声' text-center> rain </ion-select-option>
                  <ion-select-option value='海浪' text-center> sea </ion-select-option>
                <!--   <ion-select-option value='瀑布' text-center> Waterfall </ion-select-option>
                  <ion-select-option value='鸟鸣' text-center> Birds  </ion-select-option> -->
                </ion-select>
              </ion-item>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_positive_block" id='div_positive'>
            <div v-for="(sentence, index) in positives" :key="index" class='hidden'>
              <ChatMessage class="hidden"> {{ sentence }}</ChatMessage>
            </div>
            <div class="hidden">
              <ion-button fill="clear" class="ion-float-right" @click="show_otherside">
                <ion-icon :icon="chevronForwardCircle"/>
                 Next step 
              </ion-button>
              <ion-button fill="clear" class="ion-float-left" router-link="/review">
                <ion-icon :icon="chevronBackCircle"/>
                 Review 
              </ion-button>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_otherside_block" id='div_otherside'>
            <div id='otherside_init'>
              <ChatMessage class="hidden">I have focused on listening to you and understanding your feelings and needs. 
              </ChatMessage>
              <ChatMessage class="hidden">
                Now, would you be willing to try seeing it from the perspective of {{ otherside }}, and see the possible feelings and needs of {{ otherside }}? 
              </ChatMessage>
              <ChatMessage class="hidden">The feelings and needs of {{ otherside }} may differ from what you thought. </ChatMessage>
              <ChatMessage class="hidden">After exploring perspectives from both sides, you may have more new viewpoints and angles. </ChatMessage>
              <ChatMessage class="hidden">Perhaps you‘ve found your own way to deal with the problem. </ChatMessage>
              <ChatMessage class="hidden">{{ user_alias }},  are you willing to try? </ChatMessage>             
              <div class='choiceGroup hidden' id='otherside_choice'>
                <div @click="click_otherside('yes')">
                  <ChatChoice> Sure </ChatChoice>
                </div>
                <div @click="click_otherside('no')">
                  <ChatChoice> I would skip it </ChatChoice>
                </div>
              </div>
            </div>
            <div v-if="show_otherside_input" id="div_otherside_input">
              <div id='otherside_start'>
                <ChatMessage class="hidden">You chose to see the issue from the perspective of {{ otherside }}. 
                </ChatMessage>
                <ChatMessage class="hidden">Put yourself in the shoes of {{ otherside }}, what are the possible feelings and needs? 
                </ChatMessage>
                <ChatMessage class="hidden">Close your eyes, immerse yourself in the role of {{ otherside }}, slowly experience it, tell me about it. </ChatMessage>                <ChatMessage class="hidden">You can view
<router-link :to="{name:'Feeling'}">Feelings list</router-link>    and 
<router-link :to="{name:'Need'}">Needs list</router-link>                </ChatMessage>
              </div>

              <ChatMessage v-if="otherside_prompt">{{ otherside_prompt }}</ChatMessage>
              <div class="invisible" id="otherside_text">
                <ChatInputArea v-model="otherside_input" @click_submit="submit_otherside()"
                               placeholder=" Please share what you've got. "></ChatInputArea>
              </div>
            </div>
            <div id="otherside_end">
              <ChatMessage class='hidden'>
                 It is not easy seeing the feelings and needs of {{ otherside }}. Yet you tried. 
              </ChatMessage>
              <ChatMessage class='hidden'>This effort deserves gratitude, respect and appreciation. </ChatMessage>
              <ChatMessage class='hidden'>How wonderful！</ChatMessage>
              <div class='hidden'>
                <ion-button fill="clear" class="ion-float-right" @click="show_cbt">
                  <ion-icon :icon="chevronForwardCircle"/>
                   Next step 
                </ion-button>
                <ion-button fill="clear" class="ion-float-left" router-link="/review">
                  <ion-icon :icon="chevronBackCircle"/>
                   Review 
                </ion-button>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_cbt_block" id="div_cbt">
            <div id="div_cbt_init">
              <div id='cbt_choice'>
                <ChatMessage class="hidden">Everyone experiences feelings and needs. </ChatMessage>
                <ChatMessage class="hidden">Rather than avoiding them, we can try to see them, allow them, accept them, and understand them.</ChatMessage>
                <ChatMessage class="hidden">Have you noticed that behind the feelings, there are often some thoughts or beliefs? These thoughts and beliefs may not necessarily be accurate or reliable, but they influence a person‘s perspective, interpretation, and evaluation of things.  
<router-link :to="{name:'cbt'}"><b>Here's an example</b></router-link>.
                </ChatMessage>
                <ChatMessage class="hidden">I‘d like to invite you to further explore your own thoughts or beliefs, looking at the problem from more angles. 
                </ChatMessage>
                <ChatMessage class="hidden">This may be a challenge for you, would you like to try? </ChatMessage>
                <!-- <ChatMessage class="hidden">Some of the following questions that challenge your viewpoint may cause you discomfort. Please remember that you are free to choose to continue or opt out at any time. </ChatMessage>
                <ChatMessage class="hidden">Your feelings are my main concern, and I‘ll accompany you as always. This part of the exploration will take approximately: 5-15 Minutes. </ChatMessage>-->
                <div class='choiceGroup hidden'>
                  <div @click="click_cbt('yes')">
                    <ChatChoice> I want to challenge </ChatChoice>
                  </div>
                  <div @click="click_cbt('no')">
                    <ChatChoice> I would skip </ChatChoice>
                  </div>
                </div>
              </div>
              <div v-if="show_thoughts_input">
                <ChatMessage v-if="g_prime_feelings.length || g_user_chat_history.feeling_new">
                   (Your feelings: <span class="feelingSpan" v-for="(feeling, i) in g_prime_feelings" :key="i">
                  {{ feeling.split('：')[0] }}</span>
                  {{ g_user_chat_history.feeling_new }}）
                </ChatMessage>
                <ChatMessage>Please try to write down 1-3 thought/beliefs behind your feelings. </ChatMessage>                <ChatMessage v-if="thought_examples.length">
                   For example: <span class="exampleSpan" v-for="(cbt_ex, i) in thought_examples" :key="i">
                <span v-if="thought_examples.length > 1">{{ i + 1 }}.</span> {{ cbt_ex }}</span>
                </ChatMessage>
                <ChatInput v-model="thought1">1</ChatInput>
                <ChatInput v-model="thought2">2</ChatInput>
                <ChatInput v-model="thought3">3</ChatInput>
                <ChatSubmit @click_submit="submit_thoughts()"/>
              </div>
            </div>
            <div v-if="show_thoughts_question">
              <ChatMessage>{{ thought_init }}</ChatMessage>
              <ChatMessage>Thoughts  <b>{{ thought_curr }}</b></ChatMessage>
              <div v-if="check_thought">
                <ChatMessage>{{ cbt_question }}</ChatMessage>
                <div v-if="cbt_choice_index==0">
                  <ChatInputArea v-model="cbt_answer" @click_submit="submit_cbt_answer()"></ChatInputArea>
                </div>
                <!-- <div class='choiceGroup' v-if="cbt_choice_index==1">
                  <div @click="click_thought('yes')"><ChatChoice> It may be inaccurate or incomplete. </ChatChoice></div>
                  <div @click="click_thought('no')"><ChatChoice> No, it‘s accurate and on point. </ChatChoice></div>
                </div> -->
                <div style='text-align:left;margin:1em' v-if="cbt_choice_index==2">
                  <div v-for="(choice, i) in cbt_err_choices" :key="i" @click="click_thought_err(i, true)">
                    <ChatChoiceLong>{{ choice }}</ChatChoiceLong>
                  </div>
                  <div @click="click_thought_err(-1, true)">
                    <ChatChoiceLong> None of these questions apply, let‘s look at the options on the next page. </ChatChoiceLong>
                  </div>
                </div>
                <div style='text-align:left;margin:1em' v-if="cbt_choice_index==3">
                  <div v-for="(choice, i) in cbt_err_choices2" :key="i" @click="click_thought_err(i, false)">
                    <ChatChoiceLong>{{ choice }}</ChatChoiceLong>
                  </div>
                  <div @click="click_thought_err(-1, false)">
                    <ChatChoiceLong> None of those questions apply. </ChatChoiceLong>
                  </div>
                  <div @click="click_thought_err(-2, true)"><ChatChoiceLong> Go back to the previous page options. </ChatChoiceLong></div>

                  <!-- <ion-button fill="clear" class="ion-float-left" @click="click_thought_err(-2, true)">
                    <ion-icon :icon="chevronBackCircle"/>
                     Go back to the previous page options. 
                  </ion-button> -->
                </div>
                <div id="thought_detail" v-if="show_thought_detail">
                  <!-- <ChatMessage>Could you elaborate on what you think are the reasons this thought exhibits the issues mentioned above? </ChatMessage>
                  <ChatInputArea v-model="thought_err_detail"> Please enter </ChatInputArea> -->
                  <ChatMessage>If this thought were changed to be more objective and comprehensive, what might it become? </ChatMessage>
                  <ChatInputArea v-model="thought_correction"
                                 @click_submit="submit_thought_correction()"></ChatInputArea>
                </div>
              </div>
              <!-- <ion-button fill="clear" class="ion-float-right hidden" style='margin-top: 2em' id="skip_cbt"
                @click="show_advice">
                <ion-icon :icon="chevronForwardCircle" /> Skip this section. 
              </ion-button>  -->
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_cbt_general" id="div_cbt_general">
            <div v-if="g_user_chat_history.thoughts">
              <ChatMessage>Summarize your thoughts. </ChatMessage>
              <div v-for="(thought, i) in g_user_chat_history.thoughts" :key="i">
                <ChatMessage>Thoughts  {{ i + 1 }}：{{ thought.thought }}
                  <div v-if="thought.correction"> You changed it to: {{ thought.correction }}</div>
                </ChatMessage>
              </div>
            </div>
            <ChatMessage>{{ cbt_general_question }}</ChatMessage>
            <ChatInputArea v-model="cbt_general_answer" @click_submit="submit_cbt_general()"
                           placehodler=' Please enter your thoughts. '></ChatInputArea>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_goal_block" id="div_goal"> 
            <div id="div_goal1">
              <ChatMessage>If your problem is resolved and you reach an ideal state, what would it be like? How would you act? 
              </ChatMessage>
              <ChatInputArea v-model="goal_input" @click_submit="submit_goal()"
                             placeholder=" Please describe your ideal state. "></ChatInputArea>
              <ion-button fill="clear" class="center endChoice"
                          @click="submit_goal()">
                <ion-icon :icon="chevronForwardCircle"/>
                I have no idea. 
              </ion-button>
            </div> 
          </div>
        </Transition>    

        <Transition>
          <div v-if="show_exception_block" id="div_exception"> 
            <div id="div_exception1">
              <ChatMessage>In your past experiences, have there been times when things were closer to an ideal state? Or times when the problem was slightly less severe, even just a little bit? What did you do at those times? 
              </ChatMessage>
              <ChatInputArea v-model="exception_input" @click_submit="submit_exception()"
                             placeholder="Describe a scenario when it was better. "></ChatInputArea>
              <ion-button fill="clear" class="center endChoice"
                          @click="submit_exception()">
                <ion-icon :icon="chevronForwardCircle"/>
                 I have no idea. 
              </ion-button>
            </div> 
          </div>
        </Transition>  

        <Transition>
          <div v-if="show_behavior_block" id="div_behavior"> 
            <div id="div_behavior1">
              <ChatMessage>Think carefully, what can you do in the next few days to make the situation a little better? Be specific, plan out when and where you will take action, and how you will do it. 
              </ChatMessage>
              <ChatInputArea v-model="behavior_input" @click_submit="submit_behavior()"
                             placeholder=" Please describe your action plan. "></ChatInputArea>
              <ion-button fill="clear" class="center endChoice"
                          @click="submit_behavior()">
                <ion-icon :icon="chevronForwardCircle"/>
                I have no idea. 
              </ion-button>
            </div> 
          </div>
        </Transition>  

        <Transition>
          <div v-if="show_advice_block" id="div_advice">       
            <div id="div_advice_init">
              <ChatMessage>As our conversation has progressed to this point, can you think of any impressions or gains you‘ve had? Have you developed any new perspectives or seen things from different angles? 
              </ChatMessage>
              <ChatInputArea v-model="advice_input" @click_submit="submit_advice()"
                             placeholder=" Please enter your new perspectives, new angles. "></ChatInputArea>
              <ion-button fill="clear" class="center endChoice"
                          @click="submit_advice()">
                <ion-icon :icon="chevronForwardCircle"/>
                 None at the moment 
              </ion-button>
            </div>
            <div v-if="advice_response" id="div_advice_response">
              <ChatMessage class="hidden">{{ advice_input_feedback }}</ChatMessage>
              <!-- <ChatMessage class="hidden">Your diligence and courage are so admirable. </ChatMessage>
              <ChatMessage class="hidden">Please give yourself another warm hug. ！</ChatMessage> -->
              <div v-if="advice">
                <ChatMessage class="hidden">My suggestions: </ChatMessage>
                <ChatMessage class="hidden">{{ advice }}</ChatMessage>
              </div>
              <div class="hidden">
                <ion-button fill="clear" class="ion-float-right" @click="show_end_score()">
                  <ion-icon :icon="chevronForwardCircle"/>
                   Next step 
                </ion-button>
                <ion-button fill="clear" class="ion-float-left" router-link="/review">
                  <ion-icon :icon="chevronBackCircle"/>
                   Review 
                </ion-button>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_end_score_block" id="div_end_score">
            <ChatMessage>{{ user_alias }}, as we‘ve discussed to this point, I‘m very concerned about your emotions right now. </ChatMessage>            <ChatMessage>If you were to rate your troubles again, </ChatMessage>
            <ChatMessage>On a scale of 0 to 10, where 0 is the most calm and 10 is the most intense, how would you rate it now? </ChatMessage>
            <div class='choiceGroup'>
              <div v-for="i in 11" :key="i" @click="click_score(i, 1)">
                <ChatChoice>{{ i-1 }} </ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feedback_block">
            <div id="div_feedback">
              <div id='feedback_start'>
                <div v-if="score_improved">
                  <ChatMessage class="hidden">It seems your emotions have eased somewhat. </ChatMessage>
                  <ChatMessage class="hidden">I‘m curious, how did this positive change come about? 
<router-link :to="{name:'Review'}">Key points of conversation</router-link>                  </ChatMessage>
                  <ChatMessage class="hidden">Which component was the most helpful to you? </ChatMessage>
                  <ChatMessage class="hidden">Is it the part about feelings, the part about needs, or something else? 
                     (For example, seeing your own active efforts? 
                   <!--  <span v-if="has_body_sensation"> The body meditation exercise? </span>-->
                    <span v-if="g_user_chat_history.thoughts || g_user_chat_history.cbt_general"> Ponder the thoughts / Belief? </span>
                    <span v-if="g_user_chat_history.otherside && Object.keys(g_user_chat_history.otherside).length"> Consider it from another perspective? </span>
                    <span v-if="g_user_chat_history.goal"> Is the goal you want to reach clear? </span>
                    <span v-if="g_user_chat_history.exception"> Have you seen times when things were going relatively well? </span>
                    <span v-if="g_user_chat_history.behavior"> Do you have an action plan? </span>
                    ... ）
                  </ChatMessage>
                </div>
                <div v-else>
                  <ChatMessage class="hidden">It seems you are still bothered. </ChatMessage>
                  <ChatMessage class="hidden">I will continue to look for ways to better assist you. </ChatMessage>
                  <ChatMessage class="hidden">Which part do you hope me to improve? </ChatMessage>
                </div>
              </div>
              <div id="feedback_input">
                <ChatMessage v-if="feedback_prompt">{{ feedback_prompt }}</ChatMessage>
                <div class="hidden">
                  <ChatInputArea v-model="user_feedback" @click_submit="submit_feedback()"></ChatInputArea>
                </div>
                <ChatMessage v-if="g_user_chat_history.feedback && g_user_chat_history.feedback.length==2">No matter you identified someone or not, please remember :<br/>I‘m always here. 
                </ChatMessage>
              </div>
            </div>
            <div id='feedback_end'>
              <ChatMessage class="hidden">{{ user_alias }}, thank you for stopping by. </ChatMessage>              <div v-if="score_improved">
                <ChatMessage class="hidden">Your effort to make a change have inspired me. </ChatMessage>
              <!--   <ChatMessage class="hidden"><b>Share the Giraffe Ear with your friends and family, help them reduce emotional strain, make the world a little better, and earn antler tokens for yourself and your loved ones. </b></ChatMessage>
                <ChatMessage class="hidden"><b>1.  Click the three dots in the upper right corner to share this mini-program with friends or WeChat groups. </b></ChatMessage>                <ChatMessage class='hidden'><b>2.  To help your friends and family use this better, share it with them. 
                <a href="https://mp.weixin.qq.com/s/Zdp3W6LDMXEptCtq-mdpAQ " target="_blank"> This article </a></b>             
            </ChatMessage> -->
              </div>
              <div v-else>
                <ChatMessage class="hidden">I will send your feedback and opinions to my designers so that they can improve the design and serve users better. </ChatMessage>
                <ChatMessage class="hidden">Let‘s chat again next time, we‘ll both make progress. </ChatMessage>
              </div>
              <div class='choiceGroup hidden endChoice'>
                <div>
<ChatChoice router-link="/review">Review our conversation</ChatChoice>                </div>
                <div @click="newChat">
                  <ChatChoice> Start a new conversation </ChatChoice>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <div class="bottom-input">
      <ion-button fill="clear" class="ion-float-right hidden" id="skip_cbt"
                  @click="show_goal">
        <ion-icon :icon="chevronForwardCircle"/>
         Skip the "thoughts" section. 
      </ion-button>
    </div>
    <!-- <template v-slot:footer>
      <ion-footer class="ion-no-border">
        <ion-button fill="clear" class="ion-float-right hidden"  id="skip_cbt"
              @click="show_advice"><ion-icon :icon="chevronForwardCircle" /> Skip this section 
        </ion-button> 
      </ion-footer>
    </template> -->
  </base-layout>
</template>

<script setup>
// TODO: Jun Wang 2024-05-29 (the second time a user touches the input box to input, the box lost focus and covered by keyboard)
//       needs to touch below the box (on the right side) -- very weird behavior

//import $ from "jquery";
import {
  //useIonRouter,
  toastController,
  loadingController,
  IonItem,
  IonLabel,
  IonToggle,
  //IonTextarea,
  IonIcon,
  IonButton,
  //IonText,
  IonFooter,
  IonSelect, IonSelectOption,
  IonCheckbox
} from '@ionic/vue';
import {chevronBackCircle, chevronForwardCircle,} from 'ionicons/icons';
import ChatMessage from '@/components/ChatMessage.vue';
import ChatChoice from '@/components/ChatChoice.vue';
import ChatChoiceLong from '@/components/ChatChoiceLong.vue';
import ChatSubmit from '@/components/ChatSubmit.vue';
import ChatInput from '@/components/ChatInput.vue';
import ChatInputArea from '@/components/ChatInputArea.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import {store} from '@/store';
import {api} from '@/api';
import {supabase} from '@/supabase';
import {ref, onMounted, nextTick} from 'vue';
import {
  show_element_by_id, hide_element_by_id, delay, show_element_animated, scroll_to_bottom,
  loadAudio, toggleAudio, stopAudio, user_alias, gender, age, profession, nature, session, updateProfile,
  feeling_list_en as feeling_list, g_user_chat_history, use_supabase, blink_element, unblink_element, web_url, is_weixin,
  saveUserChatLocal, getUserChatLocal, g_ai_info, saveInfoLocal, getInfoLocal, deleteChatLocal, amount
} from '@/utils';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const chatpage_title = t('chatpage_title')

const debug = false;
const amount_per_chat = 99;
//const router = useIonRouter();
const user = store.user;
const isLoading = ref(true);
const new_user = ref(true);

const leaf_choices = ref([]);
const show_ai_block = ref(false);
const show_ai_result = ref(false);
// if ai_weak, default examples will be used; otherwise will be replaced by specific leaf info
const thought_examples = ref([' I expect there will be unfavorable results. ',' This is how I interpret it ...',' This is awful ',' I should / He should / I must ...',' I‘m not good enough ']);

const ROOT_ID = 0; // the top parent_id, currently 0, which may change in the future
const parent_id = ref(ROOT_ID - 1); // will be updated when user clicks a node or choice
const choices = ref([]); //{"id":0, "parent_id":0, "choice":'', "feelings":'', "needs":''}]);
const feeling_choices = ref([]); // ask user to order feelings from these choices
const recaps = ref([]); // feeling & needs recap sentences before cool_down_block
const cool_downs = ref([]); // cool down sentences in cool_down_block
const positives = ref([]); // positive sentences in positive_block

const show_greeting = ref(true);
const show_chat_start = ref(false);
const show_charge_info = ref(false);
const show_tree_node_block = ref(false);
const show_start_score_block = ref(false);
const show_end_score_block = ref(false);
const show_details_input_block = ref(false);
const show_feelings_block = ref(false);
const show_body_block = ref(false);
const show_needs_block = ref(false);
const show_recap_block = ref(false);
const show_cool_down_block = ref(false);
const show_feeling_detail_block = ref(false);
const show_feeling_choices_block = ref(false);
const show_feeling_other_block = ref(false);
const show_need_detail_block = ref(false);
const show_body_detail_block = ref(false);
const show_feeling_other_input_block = ref(false);
const show_feeling_order_block = ref(false);
const show_body_ex_block = ref(false);
const show_imagine_block = ref(false);
const show_imagine_detail_block = ref(false);
const show_new_feeling_block = ref(false);
const show_positive_block = ref(false);
const show_otherside_block = ref(false);
const show_otherside_input = ref(false);
const show_cbt_block = ref(false);
const show_thoughts_input = ref(false);
const show_thoughts_question = ref(false);
const show_thought_detail = ref(false);
const show_cbt_general = ref(false);
const show_feedback_block = ref(false);
const show_goal_block = ref(false);
const show_exception_block = ref(false);
const show_behavior_block = ref(false);
const show_advice_block = ref(false);
const advice_response = ref(false);
const ai_more_input = ref(false);
const ai_weak = ref(false);
const ai_weak_topic = ref('');
const ai_weak_placeholder = ref('The topic you‘d like to discuss is:')
const ai_detail_no = ref(' Skip details');

const feeling_curr = ref('');
const feeling_detail_question = ref('');
const first_feeling = ref(true);
const first_feeling_prompt = ref('thank you for your trust and openness. \nFeelings are regulated after being awared.\n');
const checked_feelings = ref([]);
const first_need = ref('');
const feeling_detail = ref('');
const has_body_sensation = ref(false);
const body_detail = ref('');
const need_detail = ref('');
const need_detail_confirm = ref('');
const feeling_chosen = ref('');
const feeling_other = ref('');
const feeling_hint = ref('');
const prime_feeling = ref('');
const prime_emotions = ref(''); // g_prime_feelings excluding body sensation
const imagine_detail = ref('');
const ask_new_feeling = ref(false);
const feeling_new = ref('');
const otherside_prompt = ref('');
const otherside_input = ref('');
const thought1 = ref(''), thought2 = ref(''), thought3 = ref('');
const thought_curr = ref(''), cbt_question = ref('');
const check_thought = ref(false);
const cbt_choice_index = ref(2);
const cbt_answer = ref('');
const cbt_general_question = ref('');
const cbt_general_answer = ref('');
const goal_input = ref('');
const exception_input = ref('');
const behavior_input = ref('');
const advice_input = ref('');
const advice_input_feedback = ref('');
const score_improved = ref(false);
const user_feedback = ref('');

const node_selection_question = ref('');
const do_you_need_question = ref('');
const feeling_order_prompt = ref('');
const advice = ref('');

const user_input_details = ref('');
//const user_input_question = ref(' Tell me about the specific situation? What happened? '); // non-ai version
//const user_input_question = ref(' How are you? What‘s on your mind? Please give an overview first. ');
const user_input_question = ref('What would you like to talk about today? Describe it with one sentence.');
const ai_require_input = ref(false); // check if user input length meets min ai requirement
const input_prompt = ref('');
const thought_init = ref("");
const feedback_prompt = ref('');
const otherside = ref("");
const need_repeat_start = ref('');
const passive_response = ref('');
const need_repeat = ref('');
const thought_err_detail = ref('');
const thought_correction = ref('');
const AI_INPUT_MIN = 5;
const AI_INPUT_MAX = 250;

//const review_question = " Below is your description. Please see if you need to modify or add to it. ";
const CUTOFF_OF_USER_INPUT_THAT_NEEDS_DETAILS = 20;
var review_question = "This is your input. \nWould you like to modify or add anything? "; // will be replaced by the following string if user input is too short
const review_question_that_needs_details = "Could you describe a bit more?";
const review_question_for_trouble = "Let‘s discuss your specific troubles so that our conversation can be effective. \nCould you describe a bit more?";
 
const use_weak_feelings_path = 1; // both ai_weak and ai_leaf use original ai_weak feeling_choices route

let g_ai = true;
let g_nature_audio;
let g_feelings, g_needs, g_feeling_need_recap, g_otherside;
let g_feelings_yes = [], g_needs_yes = [], g_prime_feelings = [];
//let g_need_prompt;
let g_next_feeling_index = -1, g_next_need_index = -1;
let g_thought_index = -1, g_cbt_question_index = -1, g_otherside_index = -1;
let g_feeling_curr, g_need_curr;
let g_nodeId_node_map = {};
let g_ai_leaf = false;
let g_chat_id;
let g_saved_nature;
//let g_ai_summary = 0;

const feeling_vocab = [' Grieved ', ' Melancholic ', ' Anguished ', ' Terrified ', ' Helpless ', ' Worried ', ' Angry ',
  ' Restless ', ' Distressed ', ' At a loss ', ' Fragile ', ' Feeble ', ' Embarrassed ', ' Numb ', ' Trembling with fear ',
  ' Disappointed ', ' Irritated ', ' Uncomfortable ', ' Indignant ', ' Averse ', ' Shocked ', ' Dazed ', ' Frightened ',
  ' Annoyed ', ' Envious ', ' Estranged ', ' Lonely ', ' Vigilant ', ' Desperate ', ' Collapsed ', ' Wearied ',
  ' Perplexed ', ' Jealous ', ' Dismayed ', ' Astonished ', ' Unnatural ', ' Sentimental ', ' Unhappy ', ' Surprised ',
  ' Uneasy ', ' Afraid ', ' Dislike ', ' Hopeless ', ' Remorseful ', ' Dreary ', ' Powerless ', ' Deflated ',
  ' Troubled ', ' Flustered ', ' Weary ', ' Heartache ', ' Stunned ', ' Overdrawn ', ' Impatient ', ' Panicked ',
  ' Scornful ', ' Ashamed ', ' Listless ', ' Heavy ', ' Pessimistic ', ' Weak ', ' Tense ', ' Frustrated ',
  ' Dizzy ', ' Fatigued ', ' Sad ', ' Guilty ', ' Loathe ', ' Discouraged ', ' Awkward ', ' Sorrowful ', ' Drowsy ', ' Lost ',
  ' Breathless ', ' Dissatisfied ', ' Longing ', ' Indifferent ', ' Exhausted ', ' Setback ', ' Unpleasant ', ' Conflicted ',
  ' Shy ', ' Stifled ', ' Puzzled ', ' Repressed ', ' Uncertain ', ' Hopeful ', ' Disheartened ',
  ' Reluctant ', ' Doubtful ', ' Suffering ', ' Struggling ', ' Hesitating ', ' Insecure ', ' Restless ',
  ' Heartbroken ', ' Humiliated ', ' Tired ', ' Humble ', ' Anxious ', ' Isolated ', ' Chaotic ', ' Bewildered ', ' Fearful ',
  ' Bored ', ' Hatred ', ' Agitated ', ' Infatuated ', ' Disgraced ', ' Mournful ', ' Rejected ', ' Hurt ',
  ' Eager ', ' Depressed ', ' Dejected ', ' Vexed ', ' Resentful ', ' Regretful '];
let g_feelings_from_input = new Set(); // feeling vocab from user input, no dups

let feeling_example = feeling_list; // give user hint when asking for other feelings

const cbt_questions = ['This thought may have the following distortion: " Over-interpreting "：\n (Choose the one that fits best) ',
  ' For this thought, is it possible it has the following: " Negative thinking "：\n (Choose the one that fits best) ',
  ' What evidence supports this thought? ', ' Is there any evidence against this thought? ',];

const cbt_err_choices = [' Overgeneralization: Drawing conclusions based on individual or one-sided details without looking at the whole issue. ',
  ' Exaggerating or minimizing the influence of a particular event, person, or factor. ',
  ' The tendency towards "all or nothing, black or white" thinking, seeing issues in an all-or-nothing, completely right or completely wrong way, viewing life as a black and white world with no shades of gray. ',
  ' Subjective interpretation, lacking sufficient and objective evidence, drawing conclusions based solely on one‘s own subjective feelings. ',
  ' Making decisions based on emotions and one‘s own subjective feelings. '];
const cbt_err_choices2 = [' Overlooking one‘s own strengths and advantages, or failing to see the good side of things. ',
  ' Overly emphasizing the negative impact or severity of an event, automatically thinking about the worst. ',
  ' It‘s about attaching negative labels to yourself or others. ',
  ' Having a tendency towards "self-blame", meaning that even though a situation has nothing to do with you, you think it is directed at you or caused by you. ',
  ' Using "should" and "must" ways of thinking to view issues, without flexible, open, and rich perspectives. '];
const cbt_general_questions = [' Think about what the worst possible outcome of the current situation could be. ', ' If the worst case scenario happens, how would you deal with it? ',
  ' What‘s the best possibility? ', ' What is the most likely actual situation? ', ' If this happened to your friend, how would you view it? What would you say to your friend? ']
let cbt_general_index = -1;

async function getProfile() {
  console.log('getProfile', use_supabase, window._wcprms);
  // reset
  user_alias.value = '';
  gender.value = '';
  age.value = '';
  profession.value = '';
  nature.value = '';

  const loader = await loadingController.create({});
  const toast = await toastController.create({duration: 5000});
  await loader.present();
  try {
    //console.log('get profile user', user);
    if (use_supabase) {
      if (!user.id) return; // shouldn't happen, but just in case

      var {data, error, status} = await supabase
          .from('profiles')
          .select('user_alias, gender, age, profession, nature')
          .eq('id', user.id)
          .single();
      if (error && status != 406) throw error;
    } else {
      data = await api.userInfo();
// console.debug("get userInfo:", JSON.stringify(data,null,2) );

      //never login
      if (!data) {
        if (window._wcprms?.code) {
          //wait signIn result
          const res = await api.signInWithCode(window._wcprms.code, window._wcprms.recm);
          console.log('signin with code res', res);
          if (!res) {
            //  Failed 
            getProfile();
            return
          }
          if (res && res.code !== 0) {
            //  Failed 
            getProfile();
            return
          }
          delete window._wcprms;
          data = api.user();
        }
        //else web login
      } else {
        session.value = api.session();
        store.user = data;
        // console.log('DBG> session.user = ', JSON.stringify(session.user,null,2) );
//        session.user=data;
      }
    }
    if (data) {
      // console.log('getProfile', data);
      amount.value = use_supabase ? amount_per_chat : data.horns_green + data.horns_red;
      // console.log('horn amount', amount.value);
      if (data.gender) gender.value = data.gender;
      if (data.age) age.value = data.age;
      if (data.profession) profession.value = data.profession;
      nature.value = data.nature || '雨声';
      g_saved_nature = nature.value;
      if (data.user_alias) {
        user_alias.value = data.user_alias;
        new_user.value = false;
        resumeChat();
        //hide_element_by_id('div_greeting')
        //show_positive();
        //show_cbt();
        //show_the_cool_down_part();
        // score_improved.value = true;
        // show_feedback_block.value = true;
        // show_element_sequenced('div_feedback');
      }
    } else if (use_supabase) amount.value = amount_per_chat;
  } catch (error) {
    console.log('profile error')
    toast.message = error.message;
    await toast.present();
  } finally {
    console.log('user alias', user_alias.value);
    isLoading.value = false;
    await loader.dismiss();
  }
}

// function start_chat(){
//   g_ai = false;
//   user_input_question.value = ' Tell me about the specific situation? What happened? ';
//   hide_element_by_id('div_greeting');
//   g_user_chat_history.node_path = [];
//   click_tree_node(ROOT_ID);
//   show_element_animated(show_tree_node_block);
// }

function start_chat_ai() {
  hide_element_by_id('div_greeting');
  show_element_animated(show_details_input_block);
}

function process_alias() {
  let input = user_alias.value;
  input = input.replace(/(.+) (particle) $/, '$1');
  input = input.replace(/.+ I /, '');
  input = input.replace(/ I ?.*[ To be called ]/, '');
  console.log('processed alias', input)
  if (input.length) user_alias.value = input;
}

async function submit_my_username() {
  console.log('submit_my_username',user_alias.value, user.id);
  process_alias(); // in case user input is sth like  My name is xx,  My nickname is xx,  Call me xx (particle) 

  updateProfile({user_alias: user_alias.value});
  await delay(1);

console.log('submit_my_username 22', JSON.stringify(api.user(), null,2) );
  hide_element_by_id('div_ask_alias');
  show_charge_info.value = true;
  show_element_sequenced('div_charge_info');
}

function chat_greeting() {
  hide_element_by_id('div_first_time');
  console.log('check horn', amount.value)
  if (amount.value >= amount_per_chat) { // enough amount
    hide_element_by_id('div_charge_info');
    show_chat_start.value = true;
  } else { // not enough, show charge info
    show_charge_info.value = true;
  }
  show_element_sequenced('div_greeting');
}

async function post_request(options = {}) {
options['language'] = 'en';
  if (!use_supabase) return api.ai_req(g_chat_id, options);

  let server_url = 'https://api2.langev.com/changjinglu/';
  if (options && !options.uid) {
    console.log('post', user)
    options.uid = user.id; // '00d9ac26-7700-4510-b7d9-20e53c872b66'; //debug
  }

  const response = await fetch(server_url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(options),
  });
  let error = null;
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    error = new Error(message);
  }
  const data = await response.json();
  return {data, error};
}

async function getDecisionTreeChildrenNodes(node_id) {
  const loader = await loadingController.create({});
  const toast = await toastController.create({duration: 5000});
  await loader.present();

  try {
    // let { data, error, status } = await supabase
    //   .from('decision_tree')
    //   .select(`*`)
    //   .eq('parent_id', node_id)
    //   .order('id')
    // if (error && status !== 406) throw error;

    let options = {uid: user.id, task: 'query_decision_tree', 'parent_id': node_id};
    let {data, error} = await post_request(options);
    //let {data, error} = await api.ai_req(options);
    if (error) throw error;
    if (!data) throw new Error('no data available');
    process_retrieved_tree_nodes(data, node_id);
  } catch (error) {
    toast.message = error.message;
    await toast.present();
  } finally {
    await loader.dismiss();
  }
}

async function click_tree_node(node_id) {
  reset_choices();
  g_user_chat_history.node_path.push(node_id);
  console.log(g_user_chat_history)

  let question = node_id == ROOT_ID ? " May I ask what kind of troubles you have? ?" : " Can you break down the issue further? ";
  node_selection_question.value = question;

  let leaf_node_reached = node_id in g_nodeId_node_map && g_nodeId_node_map[node_id]['feelings']
  if (!leaf_node_reached) {
    getDecisionTreeChildrenNodes(node_id);
    show_element_animated(show_tree_node_block);
  } else {
    let data_not_ready = g_nodeId_node_map[node_id]['feelings'] == 'TODO'
        || g_nodeId_node_map[node_id]['feelings'].startsWith(' See the next one ')
    if (data_not_ready) {
      let msg = 'Sorry,  The content is not ready yet. '
      const toast = await toastController.create({duration: 5000});
      toast.message = msg;
      await toast.present();
    } else {
      console.log('leaf node:', g_nodeId_node_map[node_id])
      hide_element_by_id('div_tree');
      show_element_animated(show_start_score_block);

      get_leaf_info(g_nodeId_node_map[node_id]);

    }
  }
}

function get_leaf_info(leaf_node_selected) {
  g_feelings = leaf_node_selected['feelings'].split(/\s+/)||[]; // guard against multiple spaces
  g_needs = leaf_node_selected['needs'].split('\n');
  if (leaf_node_selected['CBT_examples']) {
    thought_examples.value = leaf_node_selected['CBT_examples'].split('\n');
  }

  // g_need_prompt = g_needs[0];
  // g_needs = g_needs.slice(1);
  g_otherside = leaf_node_selected['fn_of_the_other'].split('\n');
  if (g_otherside.length && g_otherside[0].length >= 2) {
    //g_user_chat_history.otherside = {};
    otherside.value = leaf_node_selected['name_of_the_other'];  
  }
  advice.value = leaf_node_selected['advice_comment'];
  console.log('otherside', g_otherside)

  // save leaf info in case it needs to be recovered
  g_ai_info.feelings = g_feelings;
  g_ai_info.needs = g_needs;
  g_ai_info.advice_comment = advice.value;
  if (leaf_node_selected['CBT_examples']) g_ai_info.CBT_examples = thought_examples.value;
  if (otherside.value) g_ai_info.fn_of_the_other = g_otherside;
  saveInfoLocal();
}

function process_retrieved_tree_nodes(data, node_id) {
  choices.value = data;
  for (let node of data) {
    g_nodeId_node_map[node.id] = node
  }
  console.log('process retrieved tree node for :', node_id)
  if (node_id in g_nodeId_node_map) {
    parent_id.value = g_nodeId_node_map[node_id].parent_id;
  } else {
    parent_id.value = -1; // make sure " Return " won't show up at root
    //console.log('- no update on parent_id')
  }
  console.log('parent_id', parent_id.value)
}

function show_more_details() {
  if (g_ai) {
    //show_next_conversation_of_feeling();
    user_input_question.value = first_message;
    user_input_details.value = '';
    ai_more_input.value = true;
  }
  show_element_animated(show_details_input_block);
}

function click_score(score, stage) {
  if (!g_user_chat_history.scores) g_user_chat_history.scores = [];
  g_user_chat_history.scores[stage] = score;
  console.log('scores:', g_user_chat_history.scores)
  hide_element_by_id('div_start_score');
  if (stage == 0) {
    show_more_details();
  } else {
    g_user_chat_history.next = 'K';
    show_feedback();
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save score on server
}

function show_feedback() {
  hide_element_by_id('div_end_score');
  score_improved.value = g_user_chat_history.scores[1] < g_user_chat_history.scores[0];
  g_user_chat_history.feedback = [];
  show_feedback_block.value = true;
  show_element_sequenced('div_feedback');
}

function submit_feedback() {
  let end = !score_improved.value;
  let input = user_feedback.value.trim();
  // record feedback whether empty or not, so we know which question it corresponds to
  g_user_chat_history.feedback.push(input);
  saveUserChatLocal();
  hide_element_by_id('feedback_start');
  if (!end) {
    user_feedback.value = ''; //reset
    let feedback_num = g_user_chat_history.feedback.length;
    if (feedback_num == 1) {
      feedback_prompt.value = 'Have you gained anything from this session to help you deal with similar challenges in the future? ';
    } else if (feedback_num == 2) {
      feedback_prompt.value = `When we get caught in an "emotional whirlpool", it‘s easy to overlook some resources. \n`;
      feedback_prompt.value += `For example, perhaps there is someone around you who can listen to you, be with you, and give you support and help. \n`;
      feedback_prompt.value += `Do you have someone like that around you? Who might it be? `;
    } else end = true;
  }
  if (end) {
    g_user_chat_history.next = 'C'; // complete
    deleteChatLocal();
    console.log('history ', g_user_chat_history);
    saveUserChatHistory(false, true); // save feedback, set ended to true
    console.log('feedback ', g_user_chat_history.feedback)
    hide_element_by_id('feedback_input');
    show_element_sequenced('feedback_end');
  }
}

const first_message = `I hope to listen and understand you. \nCould you provide more details about your situation? `;
const chat_messages = ref([first_message]);
const disableInput = ref(false);

async function getLastChatElement() {
  await nextTick(); // wait for DOM to update
  const messages = document.querySelectorAll('.chat-message');
  return messages[messages.length - 1];
}

// new version: record each chat on screen and auto scroll
//const SUMMARY_INDICES = [1,2,3,4,5,7,9,11,13,15,17,20,23,26,30,34,38,42,47,52,58,64,80,90,100];
let g_summary_index = 0;

//let explained = false; // provide explanation if certain char (e.g  You ) appears first time; end this block if already explained
async function ai_more_details(end = 0) {
  let last_message = chat_messages.value.slice(-1);
  const coping = " Hmm, how did you respond? ";
  const coping_no = ' I didn‘t do anything. ';
  const no_more_detail = 'Switch from details to feelings';
  //const explanation = " I‘d like to understand your situation a bit more fully first, then the direction will become clearer. ";
  let input = user_input_details.value.trim();
  if (last_message == first_message && end) input = ' Nothing more to say.';
  //if (last_message == coping && end) input = coping_no;
  if (!input) { // no input
    if (end) end_details(); //move to next block if has end signal, otherwise just wait for user input
    return;
  }
  if (input != no_more_detail) {
    g_user_chat_history.details_input += '|' + input;
    saveUserChatLocal();
  }
  chat_messages.value.push(input);  // add user chat message to show on screen
  disableInput.value = true;
  user_input_details.value = ''; // reset input box
  scroll_to_bottom();

  let end_signal = input.match(/ Gone /) || input.match(/ No more to add /);  // handle input like " Gone " “ No more to add ”
  if (end_signal && input.length <= 6) end = 1;
//  let regex = new RegExp("[ You ? Should ]"); // handle input contains " You "，“ Should ” or “？”
  //let need_explain = regex.test(input);
//  let need_explain = regex.test(input)  &&  input.length <= 12;
//  if (need_explain) {
//    if (explained) end = 1;
//    else explained = true;
//  }

  // generate a response message
  let count = chat_messages.value.length;
  let duration = 0.5;
  if (last_message == first_message && 1==2) { //ask for coping first //1==2 to avoid
    await delay(duration);
    chat_messages.value.push(coping);
    input_prompt.value = " I did: ";
    ai_detail_no.value = coping_no;
  } else if (count == 4 && input == coping_no && 1==2) { // ask if user is OK upon getting coping_no as input //1==2 to avoid
    await delay(duration);
    chat_messages.value.push(' You didn‘t do anything. That‘s a choice too. \n Say a little more ');
    input_prompt.value = "";
    ai_detail_no.value = no_more_detail;
  } else if (!end) { // either a simple response or use ai_summarize
    input_prompt.value = "";
    ai_detail_no.value = no_more_detail;
    let variations = [">...", ">..."];//," Hmm "," Please continue "," Hmm "," Hmm  Hmm ", " Hmm 。\n Anything else? "," Hmm ", " Hmm 。\n And then? "," Oh "," Hmm "];
      passive_response.value = variations.random();
    let response = passive_response.value; //need_explain ? explanation : passive_response.value;
    chat_messages.value.push(response);
    let summarize_ai = input.length >= 1 && input.length <= AI_INPUT_MAX;
    //summarize_ai = summarize_ai && !need_explain;
    if (summarize_ai) { // call ai_summarize only for certain indices
      g_summary_index++;
      summarize_ai = 1; //SUMMARY_INDICES.includes(g_summary_index);
    }
    if (summarize_ai) {
      let lastChat = await getLastChatElement();
      blink_element(lastChat);
      let summary = await ai_summarize(input);
      unblink_element(lastChat);
      if (summary && !summary.startsWith(" Unable to summarize ")) {
        // changed by Jun Wang 2023-07-17, based on  Scenery 's new prompt:  Imitate Rogers , Summarize the other person‘s words in the briefest way. , Just write the response :{user_input}
        //response = summary.replaceAll(' I ', ' You ');
        response = summary.replace(/^ Respond (：|:)/, '');
        chat_messages.value[chat_messages.value.length - 1] = response;
      }
    }
  } else {
    end_details();
    return;
  }
  disableInput.value = false;
  scroll_to_bottom();
}

function end_details() {
  hide_element_by_id('div_input');
  show_feeling();
  saveUserChatHistory(); // save details_input
}

/* // old version
async function ai_more_details(end=0){ // get more details after user selected a leaf returned by ai
  let first_question = user_input_question.value.startsWith(' Would you like to say more? ');
  let next_question = " In facing this situation, did you do anything? ";
  let ai_cope = user_input_question.value == next_question;
  let more = [' Hmm, I heard you. ', ' Thank you for telling me. ', ' Oh, I see, that‘s how it is. ', ' Oh, you went through those things, I heard it all. '];
  let input = user_input_details.value.trim();
  if (input) {
    g_user_chat_history.details_input += '|' + input;
    //saveUserChatLocal();
  }
  if (input && input.length <= 6 && input.match(/^ I ? No /)) end = 1; // handle input like " No 了" “ No 有补充了”
  //if (first_question && !g_user_chat_history.details_input.includes(' I ')) {// without mentioning  I 
  if (first_question) { // just ask next question ( Cope ) whether input mentions  I  or not
    show_element_animated(show_details_input_block);
    user_input_details.value = ''; //reset
    user_input_question.value = next_question;
    input_prompt.value = " I did: ";
    ai_detail_no.value = ' I didn‘t do anything. ';
  }
  else if (ai_cope && end){
    g_user_chat_history.details_input += '| I didn‘t do anything. ';
    //saveUserChatLocal();
    show_element_animated(show_details_input_block);
    user_input_details.value = ''; //reset
    user_input_question.value = ' Hmm, you didn‘t do anything. How are you doing now? ';
    input_prompt.value = "";
    ai_detail_no.value = ' Nothing in particular I want to say anymore. ';
  }
  else if (input && !end){
    input_prompt.value = "";
    ai_detail_no.value = ' Nothing in particular I want to say anymore. ';
    user_input_details.value = ''; //reset
    let prompt = '';
    do {// make sure this prompt is not same as the last one
      prompt = more.random(); 
    } while(prompt == user_input_question.value);
    // call ai_summarize when input length [5,150] && input does not include  You  or ?
    let summarize_ai = input.length >= 5 && input.length <= AI_INPUT_MAX;
    let regex = new RegExp("[ You ?]");
    summarize_ai = summarize_ai && !regex.test(input);
    if(summarize_ai){
      //g_ai_summary = 1;
      user_input_question.value = ' I‘m listening... ';
      hide_element_by_id('div_details');
      blink_element("span_question");
      let summary = await ai_summarize(input);
      if (summary) prompt = ' Hmm, I heard you say: ' + summary.replaceAll(' I ', ' You ');
      unblink_element("span_question");
      show_element_by_id('div_details');
      user_input_question.value = prompt;
      return;
    }
    // let summarize = input.length >= 8 && input.length <= 30 && !input.includes(' You ');
    // if (summarize) {
    //   prompt = prompt.replace('。', '：') + input.replaceAll(' I ', ' You ');
    // }
    show_element_animated(show_details_input_block);
    user_input_question.value = prompt;
    //ai_more_input.value = true;
  }
  else{
    hide_element_by_id('div_input');
    show_feeling();
    saveUserChatHistory(); // save start score & details_input
  }
}
*/

function show_feeling() {
  g_user_chat_history.next = 'F'; // next block: feeling
  saveUserChatLocal();
  combine_feelings_from_input(g_user_chat_history.details_input + g_user_chat_history.leaf_weak);
  if (use_weak_feelings_path) show_element_animated(show_feeling_choices_block);
  else show_next_conversation_of_feeling();
}

function save_user_input(input) {
  if (!g_user_chat_history.details_input) g_user_chat_history.details_input = '';
  if (user_input_question.value == review_question) g_user_chat_history.details_input = input;
  else {
    let separator = g_ai_leaf ? '|' : ' ';
    g_user_chat_history.details_input += separator + input;
  }
  saveUserChatLocal();
}

async function finish_the_details() {
  if (g_ai_leaf) {  // get more details after user selected a leaf returned by ai
    ai_more_details();
    return;
  }
  let first_question = user_input_question.value.startsWith(g_ai ? " Are you okay? " : " Tell me the specifics ");
  let second_question = " Could you be a bit more specific? ";
  if (!g_ai) second_question += " For example, a specific event or detail? ?";
  let third_question = " In facing this situation, did you do anything? ";

  let input = user_input_details.value.trim();
  save_user_input(input);
  let input_combined = g_user_chat_history.details_input;
  console.log('- user input details:', input_combined)

  if (user_input_question.value == review_question) {
    ai_require_input.value = g_ai && input_combined.length < AI_INPUT_MIN;
    if (!ai_require_input.value) hide_element_by_id('div_input');
    if (g_ai) {
      if (!ai_require_input.value) ask_ai(input_combined);
      else {
        blink_element('div_ai_require', 2);
      }
    } else {
      combine_feelings_from_input(input_combined);
      show_next_conversation_of_feeling();
    }
    return;
  }

  let cut_off = g_ai ? 10 : 40;
  if (first_question && input.length < cut_off) { // first input less than cut_off
    show_element_animated(show_details_input_block);
    user_input_details.value = ''; //reset
    user_input_question.value = second_question;
  }
  // without mentioning  I 
  else if (!g_ai && user_input_question.value != third_question && !input_combined.includes(' I ')) {
    if (user_input_question.value != review_question) {
      show_element_animated(show_details_input_block);
      user_input_details.value = ''; //reset
      user_input_question.value = third_question;
    }
  } else {
    user_input_details.value = g_user_chat_history.details_input; //reset
    //let regexno = new RegExp("[No]"); // handle output contains "No”
    // added by Jun Wang (2023-10-08) to handle the case when user input is too short
    if (g_user_chat_history.details_input.length < CUTOFF_OF_USER_INPUT_THAT_NEEDS_DETAILS) 
      review_question = review_question_that_needs_details;

    const is_related_to_mental_need = await ai_identifytrouble(g_user_chat_history.details_input)
    if (is_related_to_mental_need == 'No')
      review_question = review_question_for_trouble;

    user_input_question.value = review_question;

    if (!g_user_chat_history.details_input && g_ai && debug) { // input empty
      ai_example(); // get a random ai chat example
    }
  }
}

function combine_feelings_from_input(input) {
  console.log(' - g_feelings', g_feelings);
  //find feeling vocab from user input
  feeling_vocab.forEach(function (vocab) {
    if (input.includes(vocab)) {
      g_feelings_from_input.add(vocab);
    }
  });
  //combine to g_feelings
  g_feelings_from_input.forEach(function (feeling) {
    // check if input_feeling already in g_feelings
    let index = g_feelings.indexOf(feeling);
    if (index != -1) {// exists already: move to start
      g_feelings.unshift(g_feelings.splice(index, 1)[0]);
    } else { // new: insert to start
      g_feelings.unshift(feeling);
    }
  });
  console.log(' - user input feeling', g_feelings_from_input);
  console.log(' - g_feelings', g_feelings);
  dedup_feeling();
  g_feelings = g_feelings.slice(0, 6); //  at most 6 feelings
  if (use_weak_feelings_path || ai_weak.value) feeling_choices.value = g_feelings;
  console.log(' - g_feelings limited', g_feelings);
  g_ai_info.feelings = g_feelings;
  saveInfoLocal();
}

// check if one char feeling is dup with another feeling, and dedup e.g.  Annoyed / Annoyed 躁，  Urgent /着 Urgent  
// also remove similar feeling, e.g.  Worried / Worried 
function dedup_feeling() {
  let short_list = g_feelings.filter(item => item.length == 1);
  let long_list = g_feelings.filter(item => item.length > 1);
  short_list.forEach(function (short) { // check all one_char feelings
    long_list.forEach(function (long) { // check each of the remaining feeling word
      if (long.includes(short)) { // dup found
        g_feelings = g_feelings.filter(item => item != short); // dedup from g_feelings
      }
    });
  });

  let similar_list = [[' Worried ', ' Worried ']];
  similar_list.forEach(function (pair) { // check all similar feelings
    if (g_feelings.includes(pair[0]) && g_feelings.includes(pair[1])) { // similar found
      g_feelings = g_feelings.filter(item => item != pair[0]); // dedup from g_feelings
    }
  });
}

function get_next_feeling() {
  first_feeling.value = g_next_feeling_index == -1;
  g_next_feeling_index += 1;
  if (g_feelings.length > g_next_feeling_index) {
    let feeling = g_feelings[g_next_feeling_index];
    console.log('- next feeling: ', feeling)
    //if (debug) g_next_feeling_index += 999;
    return feeling;
  } else {
    return null;
  }
}

function get_next_need() {
  g_next_need_index += 1;
  if (g_needs.length > g_next_need_index) {
    let need = g_needs[g_next_need_index];
    console.log('- next need: ', need)
    if (ai_weak.value) need = update_need_sentence(need);
    //if (debug) g_next_need_index += 999;
    return need;
  } else {
    return null;
  }
}

function update_need_sentence(need) {
  //let from_open_ai = need.startsWith('我');
  //return from_open_ai ? need.replaceAll('我', '你') + '?' : need_vocab_to_sentence(need);

  let from_open_ai = need.startsWith('I want');
  console.log('- JW:', need, '    from_open_ai:', from_open_ai)
  return from_open_ai ? need.replaceAll('I ', 'You ') + '?' : need_vocab_to_sentence(need);
}

function need_vocab_to_sentence(need) {
  /*if (need.endsWith('感')) return `你希望有${need}，是吗?`;
  if (need.includes('被')) return `你渴望${need}，是吗?`;
  if (need.startsWith('去') || need.startsWith('表达')) return `你是不是很想${need}?`;
  return `你是不是需要${need}?`;
  */
  //return ` Do you need ${need}?`;
  return `${need}`;
}

function click_feeling(yes_no) {
  g_user_chat_history.cur_feeling = g_next_feeling_index;
  if (yes_no == 'yes') {
    g_feelings_yes.push(g_feeling_curr);
    hide_element_by_id('div_feeling');
    if (g_feelings_yes.length == 1) { // 1th feeling_yes
      g_user_chat_history.feelings_yes = [];
      g_user_chat_history.feeling_detail = {};
      feeling_detail_question.value = ` Do you want to say something about ${g_feeling_curr}? `;//seems useless
    } else {
      feeling_detail_question.value = ` Do you want to say something about ${g_feeling_curr}? `;//seems useless
    }
    g_user_chat_history.feelings_yes.push(g_feeling_curr);
    show_feeling_detail_block.value = true;
    feeling_detail.value = ''; //reset
  } else {
    show_feeling_detail_block.value = false;
    show_next_conversation_of_feeling();
  }
  saveUserChatLocal();
}

function submit_feeling_detail() {
  show_feeling_detail_block.value = false;
  let detail = feeling_detail.value.trim();
  if (detail) {
    //g_user_chat_history.feeling_detail += `${g_feeling_curr}:${detail}|`;
    g_user_chat_history.feeling_detail[g_feeling_curr] = detail;
    saveUserChatLocal();
  }
  console.log('feeling detail ', g_user_chat_history.feeling_detail);
  if (use_weak_feelings_path || ai_weak.value) prime_feeling_detail();
  else show_next_conversation_of_feeling();
}

// leaf_weak: get feeling detail for prime_feelings only (excluding body sensation)
let g_prime_feeling_index = -1;
let g_prime_emotions = [];

function prime_feeling_detail() {
  hide_element_by_id('div_feeling_detail');
  g_feeling_curr = get_next_prime_feeling();
  feeling_curr.value = g_feeling_curr;
  if (g_feeling_curr == null) {
    body_ex();
  } else {
    g_user_chat_history.cur_feeling = g_prime_feeling_index;
    feeling_detail.value = ''; //reset
    show_feeling_detail_block.value = true;
    show_element_animated(show_feeling_detail_block);
    if (g_prime_feeling_index == 0) { // first prime_feeling
      g_user_chat_history.feeling_detail = {};
      feeling_detail_question.value = ` Do you want to say something about "${g_feeling_curr}"? `;
    } else {
      feeling_detail_question.value = ` Do you want to say something about "${g_feeling_curr}"? `;
    }
  }
}

function get_next_prime_feeling() {
  g_prime_feeling_index += 1;
  if (g_prime_emotions.length > g_prime_feeling_index) {
    return g_prime_emotions[g_prime_feeling_index];
  } else {
    return null;
  }
}

function reset_choices() {
  // reset choices to unchecked (needed for radio button but not ionic-button)
  // let choices = document.getElementsByClassName('choice');
  // [].forEach.call(choices, function (el) {el.checked = false; });
}

function show_next_conversation_of_feeling() {
  g_feeling_curr = get_next_feeling();
  feeling_curr.value = g_feeling_curr;
  if (g_feeling_curr == null) {
    console.log('- finish leaf node feelings, ask for body sensation');
    hide_element_by_id('div_feeling');
    hide_element_by_id('div_feeling_detail');
    g_user_chat_history.cur_feeling = 'body';
    saveUserChatLocal();
    show_element_animated(show_body_block);
  } else {
    show_element_animated(show_feelings_block);
    reset_choices();
  }
}

//leaf_weak: present feeling choices at once and get user's checked choices
function submit_feeling_choices() {
  let checked = checked_feelings.value;
  g_feelings_yes = Object.keys(checked).filter(key => checked[key]);
  console.log('Checked Feelings:', g_feelings_yes);
  hide_element_by_id('div_feeling_choices');
  g_user_chat_history.feelings_yes = [...g_feelings_yes];
  g_user_chat_history.cur_feeling = 'body';
  saveUserChatLocal();
  show_element_animated(show_body_block);
}

function click_body(yes_no) {
  console.log('body yes_no:', yes_no);

  if (yes_no == 'yes') {
    g_user_chat_history.cur_feeling = 'body_detail';
    saveUserChatLocal();
    show_body_detail();
  } else {
    ask_feeling_other();
  }
}

function show_body_detail() {
  hide_element_by_id('div_body');
  has_body_sensation.value = true;
  show_body_detail_block.value = true;
  show_element_sequenced("div_body_detail");
}

async function show_element_sequenced(div_name) {
  await nextTick();
  await delay(0.5);
  let div = document.getElementById(div_name);
  if (!div) return;
  let elements = div.getElementsByClassName('hidden');
  while (elements.length) {
    elements[0].classList.remove('hidden');
    elements = div.getElementsByClassName('hidden');
    await delay(debug ? 0.2 : 1.5);
    scroll_to_bottom();
  }
}

function submit_body_detail() {
  show_body_detail_block.value = false;
  let detail = body_detail.value.trim();
  g_user_chat_history.body_sensation = detail;
  saveUserChatLocal();
  console.log('body sensation:', g_user_chat_history.body_sensation)
  ask_feeling_other();
}

function ask_feeling_other() {
  g_user_chat_history.cur_feeling = 'other';
  hide_element_by_id('div_body');
  hide_element_by_id('div_body_detail');

  show_element_animated(show_feeling_other_block);
  console.log('feeling_chosen g_feelings_yes', g_feelings_yes)
  feeling_chosen.value = g_feelings_yes.join('，');

  // get examples for feeling_other: 
  // not already in decision tree/user input; not similar (exclude if contains same char)
  shuffleArrayInPlace(feeling_example);
  let hint = [];
  feeling_example.forEach(function (vocab) {
    if (hint.length == 3) return;
    if (!g_feelings.includes(vocab)) { // not already
      if (!vocab_has_char_set(g_feelings.join(''), vocab)) { // vocab does not contain any char in g_feelings
        if (!vocab_has_char_set(hint.join(''), vocab)) { // vocab does not contain any char in hint list so far
          hint.push(vocab);
        }
      }
    }
  });
  feeling_hint.value = hint.join(' ');

  show_element_sequenced("div_feeling_other");
}

function vocab_has_char_set(char_set, vocab) {
  let similar = false;
  for (const c of vocab) {// go through each char to check if similar
    if (char_set.includes(c)) {
      similar = true;
      return;
    }
  }
  return similar;
}

function shuffleArrayInPlace(array) {
  for (let i = 0; i < array.length; i++) {
    let k = Math.floor(Math.random() * (i + 1)); // [0, i]
    //swap the ith and kth element
    let temp = array[i];
    array[i] = array[k];
    array[k] = temp;
  }
}

function click_feeling_other(yes_no) {
  if (yes_no == 'yes') {
    hide_element_by_id('feeling_other_choice');
    show_feeling_other_input_block.value = true;
  } else {
    //show_next_conversation_of_need(true);
    get_feeling_order();
  }
}

function submit_feeling_other() {
  show_feeling_other_block.value = false;
  save_feeling_other();
  //show_next_conversation_of_need(true);
  get_feeling_order();
}

function save_feeling_other() {
  let detail = feeling_other.value.trim();
  if (detail) {
    // split by punctuations/space, and remove empty element
    detail = detail.split(/[\s，；、。!？?.,/#!$%^&*;:{}=\-_`~()]+/).filter(element => element);
    g_user_chat_history.feeling_other = detail.slice(0, 3); // get first 3
    saveUserChatLocal();
    console.log('feeling other:', g_user_chat_history.feeling_other)
  }
}

function get_feeling_order() {
  g_user_chat_history.cur_feeling = 'order';
  saveUserChatLocal();
  if (has_body_sensation.value) {
    let body = body_detail.value ? ` Physical discomfort ${body_detail.value}` : ' Physical discomfort ';
    g_feelings_yes.push(body);
  }
  if (g_user_chat_history.feeling_other) {
    //g_feelings_yes = g_feelings_yes.concat(g_user_chat_history.feeling_other);
    g_feelings_yes = [...new Set([...g_feelings_yes, ...g_user_chat_history.feeling_other])]; // concat and remove dups
    console.log('g_feelings_yes', g_feelings_yes);
  }
  if (g_feelings_yes.length > 3) {
    feeling_order_prompt.value = `${user_alias.value}, among these feelings, which one strikes you as the strongest? `;
    feeling_choices.value = [...g_feelings_yes];
    hide_element_by_id('div_feeling_other');
    show_element_animated(show_feeling_order_block);
  } else {
    g_prime_feelings = g_feelings_yes;
    show_body_ex();
  }
}

async function show_body_ex() {
  hide_element_by_id('div_feeling_other');
  hide_element_by_id('div_feeling_order');
  if (g_feelings_yes.length) g_user_chat_history.feelings_yes = g_feelings_yes; // ordered if more than 3
  if (use_weak_feelings_path || ai_weak.value) {
    g_user_chat_history.next = 'D'; // next block: feeling detail
    saveUserChatLocal();
    g_prime_emotions = [...g_prime_feelings.filter(item => !item.startsWith(" Physical discomfort "))];
    prime_feeling_detail();
  } else body_ex();
}

function body_ex() {
  g_user_chat_history.cur_feeling = '';
  g_user_chat_history.next = 'B'; // next block: body ex or imagine
  saveUserChatLocal();
  saveUserChatHistory();
  console.log('feelings_yes ', g_user_chat_history.feelings_yes);

  if (has_body_sensation.value & 1<0) {  //added & 1<0 to hide the voice block (in Chinese)
    loadAudio();
    show_element_animated(show_body_ex_block);
    show_element_sequenced("div_body_ex");
    // await delay(2);
    // show_element_by_id('body_ex_bg');    
    // show_element_by_id('ex_audio_btn');
    // await delay(10);
    // show_element_by_id('body_ex_next');
  } else show_imagine(); //show_next_conversation_of_need(true);
}

function toggleNatureAudio(checked) {
  let elem = document.getElementById('natureSelect');
  if (checked) {
    g_nature_audio.play();
    elem.removeAttribute('disabled');
  } else {
    g_nature_audio.pause();
    elem.setAttribute('disabled', '');
  }
}

function playNatureSound() {
  // if newChat() triggers ionChange, simply return
  if (!show_cool_down_block.value) return;

  if (g_nature_audio) {
    g_nature_audio.pause();
    g_nature_audio.currentTime = 0;
  }
  g_nature_audio = new Audio(`/assets/audio/${nature.value}.mp3`);
  g_nature_audio.play();

  //g_nature_audio.loop = true;
  g_nature_audio.addEventListener("timeupdate", function () {
    if (this.currentTime >= this.duration - 2) { // avoid mp3 delay in loop
      this.currentTime = 2;
      this.play();
    }
  });
}

function click_feeling_order(i) {
  console.log('feelings_yes ', g_feelings_yes);
  g_prime_feelings.push(feeling_choices.value[i]);
  if (g_prime_feelings.length < 3) {
    reset_choices();
    feeling_choices.value.splice(i, 1);
    let prime = g_prime_feelings.join('、');
    feeling_order_prompt.value = ` Hmm, you feel ${prime}. What is your next strongest feeling? `;
    show_element_animated(show_feeling_order_block);
  } else {
    console.log('prime feelings ', g_prime_feelings)
    for (let j = 2; j >= 0; j--) {//reverse loop through g_prime_feelings
      let feeling = g_prime_feelings[j];
      g_feelings_yes = g_feelings_yes.filter(item => item !== feeling);
      g_feelings_yes.unshift(feeling); // move to front
    }
    // console.log('feelings_yes ', g_feelings_yes);
    show_body_ex();
  }
}

async function show_imagine() {
  g_user_chat_history.next = 'I'; // next block: imagine
  stopAudio();
  hide_element_by_id('div_body_ex');
  show_element_animated(show_imagine_block);
  show_element_sequenced("imagine_guide");
  await delay(debug ? 0 : 12);
  show_element_by_id('imagine_choice');
}

function click_imagine(yes_no) {
  if (yes_no == 'yes') {
    hide_element_by_id('imagine_choice');
    show_imagine_detail_block.value = true;
  } else {
    show_next_conversation_of_need(true);
  }
}

function submit_imagine_detail() {
  show_imagine_detail_block.value = false;
  let detail = imagine_detail.value.trim();
  if (detail) {
    g_user_chat_history.cur_feeling = 'new';
    g_user_chat_history.imagine_detail = detail;
    saveUserChatLocal();
    console.log('imagine detail:', detail)
    show_feeling_new();
  } else {
    show_next_conversation_of_need(true);
  }
}

function show_feeling_new() {
  hide_element_by_id('imagine_guide');
  ask_new_feeling.value = true;
  let feelings = g_prime_feelings.filter(item => !item.startsWith(" Physical discomfort "));
  if (feelings.length) prime_emotions.value = feelings.join('，');
}

function click_feeling_new(yes_no) {
  hide_element_by_id('feeling_new_choice');
  if (yes_no == 'yes') {
    show_new_feeling_block.value = true;
  } else {
    show_next_conversation_of_need(true);
  }
}

function submit_new_feeling() {
  let detail = feeling_new.value.trim();
  if (detail) {
    g_user_chat_history.feeling_new = detail;
    saveUserChatLocal();
    console.log('feeling new:', detail)
  }
  show_next_conversation_of_need(true);
}

function click_need(yes_no) {
  console.log('need yes_no:', yes_no);
  need_detail_confirm.value = false;
  g_user_chat_history.cur_need = g_next_need_index;
  if (yes_no == 'yes') {
    g_need_curr = g_need_curr.replace(/^Do you /, 'You ');
    g_need_curr = g_need_curr.replace(/^Are you /, 'You are ');
    //g_need_curr = g_need_curr.replaceAll('？', '');
    g_need_curr = g_need_curr.replaceAll('?', '');
    g_need_curr = g_need_curr.replace(/, don't you/, '');
    g_need_curr = g_need_curr.replace(/, right/, '');
    //g_need_curr = g_need_curr.replace(/([^ That‘s right ]) ? /, '$1');
    need_repeat.value = g_need_curr;
    g_needs_yes.push(g_need_curr);
    if (!g_user_chat_history.needs_yes) {
      g_user_chat_history.needs_yes = [];
      g_user_chat_history.need_detail = [];
    }
    g_user_chat_history.needs_yes.push(g_need_curr);

    hide_element_by_id('need_choice');
    show_need_detail_block.value = true;
    need_detail.value = ''; //reset
  } else {
    show_need_detail_block.value = false;
    show_next_conversation_of_need();
  }
  saveUserChatLocal();
}

Array.prototype.random = function () {
  return this[Math.floor((Math.random() * this.length))];
}

function submit_need_detail() {
  show_need_detail_block.value = false;
  let detail = need_detail.value.trim();
  if (detail && !detail.includes(' You ')) {
    need_repeat.value += `\n${detail.replaceAll(' I ', ' You ')}`;
  }
  g_user_chat_history.need_detail.push(detail);
  saveUserChatLocal();
  hide_element_by_id('div_need_prompt');
  need_detail_confirm.value = true;
  let variations = [" I heard ", " I understand ", " You noticed ", " This is your observation "];
  need_repeat_start.value = variations.random();
  //show_element_sequenced('div_need_confirm');
  console.log('need detail ', g_user_chat_history.need_detail);
  show_next_conversation_of_need();
}

async function show_next_conversation_of_need(first_round = false) {
  if (first_round) {
    delete g_user_chat_history.cur_feeling;
    g_user_chat_history.next = 'N'; // set next to need
    saveUserChatLocal();
    saveUserChatHistory(); // save imagine_detail, feeling_new
    hide_element_by_id('div_imagine');
    if (g_prime_feelings.length) {
      let first = g_prime_feelings[0];
      if (!first.startsWith(' Physical discomfort ')) prime_feeling.value = first;
      else if (g_prime_feelings.length > 1) prime_feeling.value = g_prime_feelings[1];
    }
  }
  first_need.value = first_round;
  g_need_curr = get_next_need();
  if (g_need_curr != null) {
    if (need_detail_confirm.value) {
      await show_element_animated(need_detail_confirm);
      await delay(2);
      show_element_by_id("div_need_prompt");
      show_element_by_id("need_choice");
    } else show_element_animated(show_needs_block);

    do_you_need_question.value = g_need_curr;
    if (first_round && prime_feeling.value) {
      do_you_need_question.value = do_you_need_question.value.replace(/^ Are you /, ' You ');
    }
    reset_choices();
  } else {
    if (first_round) { // data missing: the feelings data is available but the needs data is missing
      do_you_need_question.value = ` Missing data !  There is " Feelings " Data ，but not " Need " Data `
    }
    console.log('- done with needs');
    delete g_user_chat_history.cur_need;
    g_user_chat_history.next = 'R'; // set next to recap
    saveUserChatLocal();
    saveUserChatHistory(); // save needs
    if (need_detail_confirm.value) {
      await show_element_animated(need_detail_confirm);
      await delay(3);
    }
    hide_element_by_id('div_need');
    show_recap();
  }
}

function set_recap_sentences() {
  let html_feelings = g_prime_feelings.join("| - ");
  let new_feelings = g_user_chat_history.feeling_new;
  if (new_feelings) html_feelings += html_feelings ? `| - ${new_feelings}` : new_feelings;
  let html_needs = g_needs_yes.join("| - ");
  //html_needs = html_needs.replaceAll('？', '');
  g_feeling_need_recap = html_feelings ? "| You feel | - " + html_feelings : '';
  if (html_feelings && html_needs) g_feeling_need_recap += "||  Because ";
  if (html_needs) g_feeling_need_recap += "| - " + html_needs;
}

async function show_recap() {
  set_recap_sentences();
  let sentences = [` I noticed your active awareness. `];
  if (g_feeling_need_recap) sentences.push(" Now let‘s pause to review and summarize. ");
  show_needs_block.value = false;
  show_element_animated(show_recap_block);

  sentences = sentences.concat(g_feeling_need_recap.split('|'))
  recaps.value = sentences;

  for (let i = 0; i < sentences.length; i++) {
    await delay(1);
    show_element_by_id(`recap${i}`);
    scroll_to_bottom();
  }
  await delay(2);
  show_element_by_id("recap_choice");
  scroll_to_bottom();
}

function click_cooldown(yes_no) {
  g_user_chat_history.cooldown = yes_no;
  saveUserChatLocal();
  saveUserChatHistory(); // save cooldown
  if (yes_no == 'Y') {
    show_recap_block.value = false;
    show_the_cool_down_part();
  } else {
    show_positive();
  }
}

function show_positive() {
  g_user_chat_history.next = 'P';  // positive
  saveUserChatLocal();
  hide_element_by_id('div_recap');
  hide_element_by_id('div_cooldown');
  if (g_nature_audio) {
    g_nature_audio.pause();
    g_nature_audio.currentTime = 0;
    if (nature.value != g_saved_nature) {
      updateProfile({nature: nature.value});
    }
  }

  // randomly select a varation, make sure it's not the one used during last session
  let name = user_alias.value;
  let feelings = g_prime_feelings.join('、') || ' Troubled ';
  let variations = [[`${name}, after hearing about your situation, `, ' I see that you are bravely facing this challenge, ',
    'thinking positively and trying, making efforts to find solutions. ', ' How did you do it! '],
    [`${name}, you feel ${feelings}. It‘s very difficult. `,` Yet you came to talk to me, to bravely face the problem. `,
      ' You are trying hard to find ways, moving towards the direction you want. ',` How did you do it! `],
    [`${name}, you're really having a hard time. `,` Today you feel ${feelings}`,
      ' You could bravely face it, ',' actively seeking direction. ',' How did you build up your strength? '],
    [`${name}, I can feel how difficult it is for you, you‘ve worked hard！`,` You feel ${feelings}. After that, you could bravely face difficulties, `, 'and work with me to manage it. ',
      ` How did you find the courage, the strength to persevere! `],
    [`${name}, you feel ${feelings}.`, ' Actually, I‘m quite curious. ', ' Many people tend to avoid troubles or problems. ',
      ' Yet you are bravely facing it, actively seeking help, seriously exploring. ', ' How did you manage to do that! ']];
  let key = 'pos';
  let savedIndex = (localStorage && localStorage.getItem(key)) || 0, randomIndex = 0;
  do {
    randomIndex = Math.floor(Math.random() * variations.length);
  } while (randomIndex == savedIndex);
  positives.value = variations[randomIndex];
  localStorage.setItem(key, randomIndex);

  show_positive_block.value = true;
  show_element_sequenced('div_positive');
}

function show_otherside() {
  hide_element_by_id('div_positive');
  if (otherside.value) {
    //show_element_animated(show_otherside_block);
    g_user_chat_history.next = 'O';  // set next to otherside
    saveUserChatLocal();
    show_otherside_block.value = true;
    show_element_sequenced('otherside_init');
  } else show_cbt();
}

function show_cbt() {
  g_user_chat_history.next = 'T'; // set next to thought
  delete g_user_chat_history.cur_otherside;
  saveUserChatLocal();
  hide_element_by_id('div_otherside');
  show_element_animated(show_cbt_block);
  show_element_sequenced('div_cbt');
}

function click_otherside(yes_no) {
  if (yes_no == 'yes') {
    g_user_chat_history.cur_otherside = 'Y';
    start_otherside_input();
    saveUserChatLocal();
  } else {
    hide_element_by_id('div_otherside');
    show_cbt();
  }
}

async function start_otherside_input() {
  g_user_chat_history.otherside = {};
  hide_element_by_id('otherside_init');
  show_element_animated(show_otherside_input);
  await show_element_sequenced('div_otherside_input');
  // use 'invisible' instead of 'hidden' so textarea placeholder text will display properly
  // (if hidden, dimensions of autogrow textarea cannot be calculated)
  document.getElementById('otherside_text').classList.remove('invisible');
}

function submit_otherside() {
  g_user_chat_history.cur_otherside = g_otherside_index;
  let input = otherside_input.value.trim();
  if (input) {
    //g_user_chat_history.otherside.push(input); // save empty input too, to correspond to prompt
    let prompt = g_otherside[g_otherside_index] || ''; // no prompt, set empty string as key
    g_user_chat_history.otherside[prompt] = input; // save as dictionary key-value pair (prompt:input)
  }
  show_next_otherside_prompt();
  saveUserChatLocal();
}

function get_next_otherside() {
  g_otherside_index += 1;
  if (g_otherside.length > g_otherside_index) return g_otherside[g_otherside_index];
  else return null;
}

function show_next_otherside_prompt() {
  let first = g_otherside_index == -1;
  otherside_prompt.value = get_next_otherside();
  if (otherside_prompt.value) {
    if (first) {
      hide_element_by_id('otherside_start');
      otherside_prompt.value = 'My perspective: ' + otherside_prompt.value;
      if (otherside_input.value) {
        otherside_prompt.value = `${user_alias.value},  Thank you for trying to consider it from another perspective. \n${otherside_prompt.value}`;
      }
    }
    otherside_input.value = ''; //reset
  } else { // done with otherside perspective
    show_otherside_input.value = false;
    show_element_sequenced('otherside_end');
    if (Object.keys(g_user_chat_history.otherside).length) saveUserChatHistory(); // save otherside
  }
}

function click_cbt(yes_no) {
  if (yes_no == 'yes') {
    g_user_chat_history.cur_cbt = 'Y';
    saveUserChatLocal();
    show_thoughts();
  } else {
    show_goal();
  }
}

function show_thoughts() {
  hide_element_by_id('cbt_choice');
  show_element_animated(show_thoughts_input);
  show_element_by_id('skip_cbt');
}

function submit_thoughts() {
  hide_element_by_id('div_cbt_init');
  save_thought(thought1);
  save_thought(thought2);
  save_thought(thought3);
  saveUserChatLocal();
  console.log('thoughts ', g_user_chat_history.thoughts);

  check_thought.value = true;
  show_next_cbt_question();
}

function save_thought(name) {
  let thought = name.value.trim();
  if (thought) {
    if (!g_user_chat_history.thoughts) {
      g_user_chat_history.thoughts = [];
    }
    g_user_chat_history.thoughts.push({'thought': thought, 'cbt_yes': '', 'correction': '', 'details': []});
  }
}

function get_next_thought() {
  g_thought_index++;
  g_user_chat_history.cur_cbt = g_thought_index;
  let thoughts = g_user_chat_history.thoughts;
  if (thoughts && g_thought_index < thoughts.length) return thoughts[g_thought_index].thought;
  else return null;
}

function submit_thought_correction() {
  let thought = g_user_chat_history.thoughts[g_thought_index];
  //thought['details'].push(thought_err_detail.value.trim());
  thought['correction'] = thought_correction.value.trim();
  saveUserChatLocal();
  show_thought_detail.value = false;
  cbt_choice_index.value = 0;
  thought_err_detail.value = '';
  thought_correction.value = '';
  show_next_cbt_question();
}

function submit_cbt_answer() {
  let detail = g_user_chat_history.thoughts[g_thought_index]['details'];
  detail.push(cbt_answer.value.trim());
  saveUserChatLocal();
  cbt_answer.value = ''; // reset
  show_next_cbt_question();
}

function show_thought_init(first) {
  if (first) thought_init.value = ` Your first thought `;
  else thought_init.value = ``;
}

function show_next_cbt_question() {
  let count = cbt_questions.length;
  g_cbt_question_index++;
  console.log('cbt_question_index ', g_cbt_question_index, cbt_choice_index.value);
  // user chose a thought err for first question, skip the related second question
  if (g_cbt_question_index == 1 && cbt_choice_index.value == 0) {
    g_cbt_question_index++;
  }
  let first = g_thought_index == -1;
  if (g_cbt_question_index >= count || first) {
    let thought = get_next_thought();
    if (thought) {
      thought_curr.value = `${g_thought_index + 1}：${thought}`;
      g_cbt_question_index = g_cbt_question_index % count;
      show_thought_init(first);
      show_element_animated(show_thoughts_question);
      cbt_choice_index.value = 2;
    } else { // all thoughts examined
      console.log(g_user_chat_history.thoughts);
      hide_element_by_id('div_cbt');
      g_user_chat_history.next = 'G';
      saveUserChatLocal();
      next_cbt_general();
    }
  } else {
    show_element_animated(check_thought);
  }
  cbt_question.value = cbt_questions[g_cbt_question_index];
}

function next_cbt_general() {
  g_user_chat_history.cur_cbt = cbt_general_index;
  saveUserChatLocal();
  cbt_general_index += 1;
  if (cbt_general_questions.length > cbt_general_index) {
    cbt_general_question.value = cbt_general_questions[cbt_general_index];
    show_element_animated(show_cbt_general);
  } else {
    console.log("cbt_general", g_user_chat_history.cbt_general);
    show_goal();
  }
}

function submit_cbt_general() {
  if (!g_user_chat_history.cbt_general) g_user_chat_history.cbt_general = [];
  g_user_chat_history.cbt_general.push(cbt_general_answer.value.trim());
  saveUserChatLocal();
  cbt_general_answer.value = ''; // reset
  next_cbt_general();
}

// function click_thought(yes_no) {
//   if (yes_no=='yes') {
//     cbt_choice_index.value = 2;
//   }
//   else{
//     cbt_choice_index.value = 0;
//   }
//   show_next_cbt_question();
// }

function click_thought_err(id, has_next) {
  console.log('thought err', id);
  if (id == -2) {
    cbt_choice_index.value = 2;
    g_cbt_question_index -= 2;
    show_next_cbt_question();
  } else if (id == -1) {
    show_thought_detail.value = false;
    cbt_choice_index.value = has_next ? 3 : 0;
    show_next_cbt_question();
  } else {
    cbt_choice_index.value = 4;
    show_element_animated(show_thought_detail);
    let err = has_next ? cbt_err_choices[id] : cbt_err_choices2[id];
    cbt_question.value = ` You think the issue with this thought is: \n${err}`;
    g_user_chat_history.thoughts[g_thought_index]['cbt_yes'] = err;
    saveUserChatLocal();
  }
}

function show_goal() {
  hide_element_by_id('skip_cbt');
  hide_element_by_id('div_cbt');
  hide_element_by_id('div_cbt_general');
  show_element_animated(show_goal_block);
  delete g_user_chat_history.cur_cbt;
  g_user_chat_history.next = 'L'; // set next to goal
  if (g_user_chat_history.thoughts || g_user_chat_history.cbt_general) saveUserChatHistory(); //save cbt
  saveUserChatLocal();
}

function show_exception() {
  hide_element_by_id('div_goal');
  show_element_animated(show_exception_block);
  delete g_user_chat_history.goal;
  g_user_chat_history.next = 'X'; // set next to exception
  if (g_user_chat_history.goal) saveUserChatHistory(); //save goal
  saveUserChatLocal();
}

function show_behavior() {
  hide_element_by_id('div_exception');
  show_element_animated(show_behavior_block);
  delete g_user_chat_history.exception;
  g_user_chat_history.next = 'H'; // set next to behavior
  if (g_user_chat_history.exception) saveUserChatHistory(); //save exception
  saveUserChatLocal();
}

function show_advice() {
  hide_element_by_id('div_behavior');
  show_element_animated(show_advice_block);
  delete g_user_chat_history.behavior;
  g_user_chat_history.next = 'A'; // set next to advice/angle
  if (g_user_chat_history.behavior) saveUserChatHistory(); //save behavior
  saveUserChatLocal();
}

function submit_advice() {
  hide_element_by_id('div_advice_init');
  let input = advice_input.value.trim();
  if (input) {
    g_user_chat_history.angle_new = input;
    advice_input_feedback.value = " Thank you for being willing to try viewing the issue from new perspectives and angles. \n You are truly  willing to open up, allow and accept different viewpoints and angles. "
  } else {
    advice_input_feedback.value = ` Oh, no new perspectives or insights for now, that‘s okay. \n\n Your ability to express yourself candidly today is a step forward. `;
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save angle_new
  advice_response.value = true;
  show_element_sequenced('div_advice_response');
}

function submit_goal() {
  hide_element_by_id('div_goal1');
  let input = goal_input.value.trim();
  if (input) {
    g_user_chat_history.goal = input;
  } else { g_user_chat_history.goal = '';
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save goal
  show_exception_block.value = true;
  show_element_sequenced('show_goal_block');
}

function submit_exception() {
  hide_element_by_id('div_exception1');
  let input = exception_input.value.trim();
  if (input) {
    g_user_chat_history.exception = input;
  } else { g_user_chat_history.exception = '';
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save exception
  show_behavior_block.value = true;
  show_element_sequenced('show_exception_block');
}

function submit_behavior() {
  hide_element_by_id('div_behavior1');
  let input = behavior_input.value.trim();
  if (input) {
    g_user_chat_history.behavior = input;
  } else { g_user_chat_history.behavior = '';
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save behavior
  show_advice_block.value = true;
  show_element_sequenced('show_behavior_block');
}

function show_end_score() {
  g_user_chat_history.next = 'S'; // end score
  saveUserChatLocal();
  hide_element_by_id('div_advice');
  show_element_animated(show_end_score_block);
}

async function ai_example() {
  try {
    let options = {task: 'fetch_chat_example', 'index': Math.floor(Math.random() * 3001),};
    let {data, error} = await post_request(options);
    //let {data, error} = await api.ai_req(options);
    console.log('ai example', data);
    if (error) throw error;
    if (!data) throw new Error('no ai example available');
    user_input_details.value = data.start + data.continue;
  } catch (error) {
    console.log(error.message);
  }
}

async function ask_ai(input) {
  await saveUserChatHistory(true); // insert first input
  let ai_predictPromise = ai_predict(input.slice(0, AI_INPUT_MAX)); //callOpenAI(input);
  show_ai_block.value = true;
  await show_element_sequenced('ai_warmup'); // run this immediately
  await ai_predictPromise; // now wait for ai_predict to finish
  show_leaf_choices();
}

async function ai_predict(input) {
  try {
    let options = {task: 'predict', 'user_input': input};
    let {data, error} = await post_request(options);
    console.log('ai result', data, error);
    if (error) throw error;
    if (!data || !(data instanceof Array)) throw new Error('no ai predict available');
    g_ai_info.leaf_choices = data;
    saveInfoLocal();
    leaf_choices.value = data;
  } catch (error) {
    console.log(error.message);
  }
}

async function show_leaf_choices() {
  if (!leaf_choices.value.length) {
    ai_weak.value = true;
    show_ai_result.value = false;
    ai_weak_placeholder.value = "";
  } else {
    show_element_animated(show_ai_result);
  }
}

async function ai_leaf(leaf_text, from_open_ai = false) {
  hide_element_by_id('div_ai_choice');
  hide_element_by_id('div_ai');
  show_element_animated(show_start_score_block);

  let ai_weak_task = from_open_ai ? 'summarize_feelings_and_needs' : 'get_feelings_and_needs_only';
  let task = ai_weak.value ? ai_weak_task : 'get_feelings_needs_etc';
  if (ai_weak.value) leaf_text = leaf_text.slice(0, AI_INPUT_MAX);
  try {
    let options = {task: task, 'user_input': leaf_text};
    let {data, error} = await post_request(options);
    console.log('ai leaf', data, error);
    if (error) throw error;
    if (!data) throw new Error('no ai leaf available');
    g_ai_leaf = true;
    if (ai_weak.value) {
      // called twice
      // 1. get feelings & needs from our own model
      // 2. update needs from open_ai and update feelings in case our own model is unreachable
      if (!g_feelings) g_feelings = data['feelings'];
      g_needs = data['needs'];
      g_ai_info.feelings = g_feelings;
      g_ai_info.needs = g_needs;
      saveInfoLocal();
      if (from_open_ai) return;
    } else {
      get_leaf_info(data);
    }
    //combine_feelings_from_input(g_user_chat_history.details_input);
    saveUserChatHistory(); // update chat history: leaf
  } catch (error) {
    console.log(error.message);
  }
}

/*async function ai_summarize(input) {
  try {
    let options = {task: 'summarize', 'user_input': input};
    let {data, error} = await post_request(options);
    //let {data, error} = await api.ai_req(options);
    console.log('ai summarize', data, error);
    if (error || !data) return null;
    return data.summary;
  } catch (error) {
    console.log(error.message);
    return null;
  }
}*/

async function ai_summarize(input) {
  try {
    let prompt_flag;
    if (!g_user_chat_history.multi_turn_chat_seq) {
      let input_details = g_user_chat_history.details_input; // + ". " + input;
      g_user_chat_history.multi_turn_chat_seq = [input_details];
      prompt_flag = 1;
    } else {
      g_user_chat_history.multi_turn_chat_seq.push(input);
      prompt_flag = 2;
    }

    let options = {
      task: 'gpt_multi_turn_chat',
      prompt_flag: prompt_flag,
      chat_seq: g_user_chat_history.multi_turn_chat_seq
    };
    let {data, error} = await post_request(options);
    console.log('ai summarize', data, error);

    let gpt_response_text = data.gpt_multi_turn_chat;
    if (gpt_response_text.includes("professional help") & gpt_response_text.length<100) {
      gpt_response_text = " I will do my best to help you, and you can seek help from professional psychotherapists. "
    }
    console.log('GPT response:', gpt_response_text)

    if (error || !data) return null;

    g_user_chat_history.multi_turn_chat_seq.push(gpt_response_text);
    return gpt_response_text;

  } catch (error) {
    console.log(error.message);
    return null;
  }
}

async function ai_identifytrouble(input) {
  await saveUserChatHistory(true); // insert first input

  try {
    console.log('ai gpt_input:', input);
    let options = {task: 'gpt_is_related_to_mental_need', 'user_input': input};
    let {data, error} = await post_request(options);
    console.log('ai gpt_is_related_to_mental_need', data, error);
    if (error || !data) return null;
    return data.gpt_is_related_to_mental_need;
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

async function click_ai_choice(choice) {
  console.log('click ai choice', choice);
  if (choice == -1) {
    ai_weak.value = true;
    await delay(0.5);
    scroll_to_bottom(500);
    return;
  }
  g_user_chat_history.leaf = choice;
  saveUserChatLocal();
  ai_weak.value = false;
  ai_leaf(choice);
}

function submit_ai_weak() {
  let input = ai_weak_topic.value.trim();
  console.log('ai weak topic', input);
  if (!input) return;
  g_user_chat_history.leaf_weak = input;
  saveUserChatLocal();
  input += ' ' + g_user_chat_history.details_input;
  //show_element_by_id('ai_choice_msg');
  ai_leaf(input); // call our own model to get quick return for feelings
  ai_leaf(input, true); // call open ai model to get more verbose and accurate needs
}

var g_has_already_created_chat_history_record = false;
async function saveUserChatHistory(insert_new = false, ended = false) {
  console.log('save chat history', g_user_chat_history);
  //if (debug) return;
  //console.log(g_chat_id, insert_new);
  //const loader = await loadingController.create({});
  //const toast = await toastController.create({ duration: 5000 });
  try {
    //await loader.present();

    if (insert_new && !g_has_already_created_chat_history_record) {

      g_has_already_created_chat_history_record = true;
      if (use_supabase) {
        const updates = {uid: user.id, history: g_user_chat_history};
        let {data, error} = await supabase.from('user_track').insert(updates).select('id').single();
        if (error) throw error;
        g_chat_id = data.id;
      } else {
        let data = await api.userTrackInsert(g_user_chat_history);
        console.log('insert', data)
        if (data.code) throw new Error(data.msg);
        g_chat_id = data.id;
      }
      g_ai_info.chat_id = g_chat_id;
      saveInfoLocal();
      console.log('chat id', g_chat_id);
    } else {
      if (use_supabase) {
        let updates = {history: g_user_chat_history};
        if (ended) updates['ended_at'] = new Date();
        let {error} = await supabase.from('user_track').update(updates, {
          returning: 'minimal',
        }).eq('id', g_chat_id);
        if (error) throw error;
      } else {
        let ended_at = ended ? new Date().getTime() : null;
        console.log('ended at', ended_at)
        let error = await api.userTrackUpdate(g_chat_id, g_user_chat_history, ended_at);
        if (error.code) throw new Error(error.msg);
      }
    }
  } catch (error) {
    console.log(error.message)
    //toast.message = error.message;
    //await toast.present();
  } finally {
    //await loader.dismiss();
  }
}

async function show_the_cool_down_part() {
  let cool_down_sentences = [' Slow down ', ' Take a deep breath ', ' Quietly experience these feelings and needs. ', ' Stay a bit longer  (over 5 minutes)', ' I am with you '];
  if (!g_feeling_need_recap) cool_down_sentences[2] = ' Quietly feel your body. ';
  else if (!g_needs_yes.length) cool_down_sentences[2] = ' Quietly experience your feelings. ';
  else if (!g_feelings_yes.length) cool_down_sentences[2] = ' Quietly experience your needs. ';
  show_element_animated(show_cool_down_block);
  if (g_feeling_need_recap) cool_down_sentences = cool_down_sentences.concat(g_feeling_need_recap.split('|'));
  cool_downs.value = cool_down_sentences;

  // reference of scrollToBottom: 
  // https://github.com/ionic-team/ionic-docs/blob/main/static/demos/api/content/index.html
  console.log('cool_down_sentences', cool_down_sentences);
  for (let i = 0; i < cool_down_sentences.length; i++) {
    let duration = debug ? 0 : i <= 7 ? 2 : 5;
    await delay(duration); //slower when showing feelings/needs
    //appendChatMessageComponent(sent);
    show_element_by_id(`cool_down${i}`);
    scroll_to_bottom();
  }
  // play nature sound
  playNatureSound();
  console.log('audio playing', !g_nature_audio.paused);
  if (g_nature_audio.paused) {// if play() failed, let user interact to play
    document.getElementById('natureToggle').checked = false;
    //toggleNatureAudio(false); 
  }
  show_element_by_id('cool_down_nature');
  scroll_to_bottom();
}

// const testRef = ref(null);
// defineExpose({ testRef });

function newChat() {
  if (is_weixin.value) {
    console.log("web_url = " + web_url.value);
    window.location.replace(web_url.value);
  } else {
    window.location.reload();
  }
  //router.replace('/tabs/chat');
  //router.go();
}

async function resumeChat() {
  // if(debug) {
  //   g_ai_leaf = true;
  //   show_more_details();
  //   return;
  // }
  getUserChatLocal();
  getInfoLocal();
  console.log('chat', g_user_chat_history);
  console.log('info', g_ai_info);
  let input = g_user_chat_history.details_input;
  g_chat_id = g_ai_info.chat_id;
  if (!input || !g_chat_id) { // this is a new chat
    chat_greeting();
    return;
  }
  show_greeting.value = false;
  let has_leaf = g_user_chat_history.leaf || g_user_chat_history.leaf_weak;
  leaf_choices.value = g_ai_info.leaf_choices;
  if (!has_leaf) {// no leaf yet
    if (!leaf_choices.value) { // no leaf choices available
      user_input_details.value = input;
      user_input_question.value = review_question;
      start_chat_ai();
    } else { // show leaf choices
      show_ai_block.value = true;
      await show_element_sequenced('ai_warmup');
      show_leaf_choices();
    }
    return;
  }
  g_ai_leaf = true;
  if (g_user_chat_history.leaf_weak) ai_weak.value = true;
  g_feelings = g_ai_info.feelings;
  g_needs = g_ai_info.needs;
  if (!g_feelings || !g_needs) { // no leaf info
    if (ai_weak.value) {
      let input = g_user_chat_history.leaf_weak + ' ' + g_user_chat_history.details_input;
      ai_leaf(input); // call our own model to get quick return for feelings
      ai_leaf(input, true); // call open ai model to get more verbose and accurate needs
    } else {
      ai_leaf(g_user_chat_history.leaf);
    }
  } else if (!ai_weak.value) {
    advice.value = g_ai_info.advice_comment;
    if (g_ai_info.fn_of_the_other) {
      g_otherside = g_ai_info.fn_of_the_other;
      if (g_otherside.length && g_otherside[0].length >= 2) otherside.value = g_ai_info.name_of_the_other;
    }
  }
  if (!g_user_chat_history.scores) { // no start socre
    show_element_animated(show_start_score_block);
    return;
  }
  if (!g_user_chat_history.details_input.includes('|') && !g_user_chat_history.next) { // missing more details
    show_more_details();
    return;
  }

  let cur_feeling = g_user_chat_history.cur_feeling;
  if (g_user_chat_history.feelings_yes) g_feelings_yes = [...g_user_chat_history.feelings_yes];
  if ('body_sensation' in g_user_chat_history) {
    has_body_sensation.value = true;
  }
  console.log('g_feelings_yes', g_feelings_yes)

  if (!g_user_chat_history.next || g_user_chat_history.next == 'F') { // feelings
    if (cur_feeling == 'order') {
      get_feeling_order();
      return;
    }
    if (cur_feeling == 'other') {
      if (g_user_chat_history.feeling_other) {
        feeling_other.value = g_user_chat_history.feeling_other.join(' ');
      }
      ask_feeling_other();
      return;
    }
    if (cur_feeling == 'body') {
      show_element_animated(show_body_block);
      return;
    }
    if (cur_feeling == 'body_detail') {
      show_body_detail();
      return;
    }
    if (typeof cur_feeling === 'number' && Number.isInteger(cur_feeling)) {
      g_next_feeling_index = g_user_chat_history.cur_feeling;
    }
    show_feeling();
    return;
  }

  g_prime_feelings = g_feelings_yes.slice(0, 3);
  console.log('prime feelings', g_prime_feelings)
  if ((use_weak_feelings_path || ai_weak.value) && g_user_chat_history.next == 'D') { // leaf_weak, feeling detail
    cur_feeling = g_user_chat_history.cur_feeling;
    console.log('cur_feeling', cur_feeling)
    if (typeof cur_feeling === 'number' && Number.isInteger(cur_feeling)) {
      g_prime_feeling_index = g_user_chat_history.cur_feeling;
    }
    show_body_ex();
    return;
  }
  if (g_user_chat_history.next == 'B') { // body ex or imagine
    body_ex();
    return;
  }
  if (g_user_chat_history.next == 'I') { // body ex imagine
    if (cur_feeling == 'new') {
      feeling_new.value = g_user_chat_history.feeling_new;
      show_imagine_block.value = true;
      show_feeling_new();
      return;
    }
    if (g_user_chat_history.imagine_detail) imagine_detail.value = g_user_chat_history.imagine_detail;
    if (imagine_detail.value) {
      show_imagine_block.value = true;
      show_imagine_detail_block.value = true;
    } else show_imagine();
    return;
  }

  let cur_need = g_user_chat_history.cur_need;
  if (g_user_chat_history.needs_yes) g_needs_yes = [...g_user_chat_history.needs_yes];
  if (g_user_chat_history.next == 'N') { // needs
    let first_need = true;
    if (typeof cur_need === 'number' && Number.isInteger(cur_need)) {
      g_next_need_index = g_user_chat_history.cur_need;
      first_need = false;
    }
    show_next_conversation_of_need(first_need);
    return;
  }
  if (g_user_chat_history.next == 'R') { // recap
    if (g_user_chat_history.cooldown == 'Y') {
      set_recap_sentences();
      show_the_cool_down_part();
    } else show_recap();
    return;
  }
  if (g_user_chat_history.next == 'P') { // positive
    show_positive();
    return;
  }
  if (g_user_chat_history.next == 'O') { // otherside
    await show_element_animated(show_otherside_block);
    let cur_otherside = g_user_chat_history.cur_otherside;
    if (typeof cur_otherside === 'number' && Number.isInteger(cur_otherside)) {
      show_otherside_input.value = true;
      g_otherside_index = cur_otherside;
      await show_next_otherside_prompt();
      let otherside_text = document.getElementById('otherside_text');
      if (otherside_text) otherside_text.classList.remove('invisible');
    } else if (cur_otherside == 'Y') {
      start_otherside_input();
    } else show_otherside();
    return;
  }
  if (g_user_chat_history.next == 'T') { // thoughts
    let thoughts = g_user_chat_history.thoughts ? [...g_user_chat_history.thoughts] : [];
    console.log('thoughts', thoughts)
    let cbt = g_user_chat_history.cur_cbt;
    if (g_ai_info.CBT_examples) thought_examples.value = g_ai_info.CBT_examples;
    // cannot simply use show_next_cbt_question()
    if (typeof cbt === 'number' && Number.isInteger(cbt) && thoughts.length) {
      g_thought_index = cbt; // next thought
      if (cbt >= thoughts.length) {// check validity
        hide_element_by_id('div_cbt');
        next_cbt_general();
        return;
      }
      show_cbt_block.value = true;
      show_thoughts_question.value = true;
      check_thought.value = true;
      let thought = thoughts[cbt];
      if (thought.details.length || thought.correction) { // got err/correction already
        // details has length of 1 or 2, and we have 2 questions before details
        g_cbt_question_index = Math.min(thought.details.length + 2, cbt_questions.length - 1);
        cbt_question.value = cbt_questions[g_cbt_question_index];
        cbt_choice_index.value = 0;
      } else if (thought.cbt_yes) { // got err, ask for correction
        cbt_choice_index.value = 4;
        show_thought_detail.value = true;
        cbt_question.value = ` You think the issue with this thought is: \n${thought.cbt_yes}`;
        g_cbt_question_index = 1;
      } else { // no err/correction yet,start from the first question
        g_cbt_question_index = 0;
        cbt_question.value = cbt_questions[g_cbt_question_index];
        cbt_choice_index.value = 2;
      }
      thought_curr.value = `${g_thought_index + 1}：${thought.thought}`;
      show_thought_init(cbt == 0);
    } else if (cbt == 'Y') {
      show_cbt_block.value = true;
      show_thoughts();
      // if (thoughts.length >= 1) thought1.value = thoughts[0].thought;
      // if (thoughts.length >= 2) thought2.value = thoughts[1].thought;
      // if (thoughts.length == 3) thought3.value = thoughts[2].thought;
    } else show_cbt();
    return;
  }
  if (g_user_chat_history.next == 'G') { // cbt general
    let cbt = g_user_chat_history.cur_cbt;
    if (typeof cbt === 'number' && Number.isInteger(cbt)) {
      cbt_general_index = cbt;
    }
    next_cbt_general();
    return;
  }
  if (g_user_chat_history.next == 'L') { // goal
    show_goal();
    return;
  }  
  if (g_user_chat_history.next == 'X') { // exception
    show_exception();
    return;
  } 
  if (g_user_chat_history.next == 'H') { // behavior
    show_behavior();
    return;
  } 
  if (g_user_chat_history.next == 'A') { // advice
    show_advice();
    return;
  }
  if (g_user_chat_history.next == 'S') { // end score
    show_end_score();
    return;
  }
  if (g_user_chat_history.next == 'K') { // feedback
    show_feedback();
    return;
  }
}

onMounted(async () => {
  //if (!store.user.id) router.replace('/');
  await getProfile();

  window.onbeforeunload = function () {
    if (g_user_chat_history.next == 'C') {//complete
      deleteChatLocal();
      return;
    }

    // in case user exits before hitting submission button, save input on screen if any
    let input = user_input_details.value.trim();
    if (input) save_user_input(input);
    input = imagine_detail.value.trim();
    save_feeling_other();
    if (input) g_user_chat_history.imagine_detail = input;
    // input = feeling_new.value;
    // if (input && input.trim()) g_user_chat_history.feeling_new = input;
    // save_thought(thought1);
    // save_thought(thought2);
    // save_thought(thought3);
    saveUserChatLocal();
  }
});
</script>

<style scoped>
p {
  margin-top: 1.5em;
}

.choiceGroup {
  /*margin-left: 10%;*/
  padding: 1em;
}

.pause {
  height: 15px;
}

#recap_choice {
  margin-top: 40px;
}

#body_ex_bg {
  margin: 1px 1%;
  width: 98%;
  max-height: 300px;
}

.endChoice {
  margin-top: 2em;
  font-size: 15px;
}

.feelingSpan {
  margin-right: 5px;
}

.exampleSpan {
  margin-right: 10px;
}

ion-checkbox::part(container) {
  border-radius: 30%;
  border: 2px solid var(--ion-color-primary);
}

.checkItem {
  /* margin-left: 30%;
  margin: auto 30%; */
  margin: auto 10%;
  font-size: 1.25em;
}

/* .transition {
  opacity: 0;
  transition: opacity 0.8s ease;
} */

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1s linear infinite;
}

.bottom-input {
  width: 100%;
  /*
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 75px);
   */
  text-align: right;
}

#messagesWrapper {
  height: 100%;
  overflow-y: scroll;
  /*padding-bottom: 100px;

   */
  padding-bottom: 30px;
  box-sizing: border-box;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
